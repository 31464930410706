import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import AceEditor from 'react-ace';
import 'ace-builds/webpack-resolver';
import 'ace-builds/src-noconflict/ace';
import 'ace-builds/src-noconflict/ext-keybinding_menu';
import 'ace-builds/src-noconflict/mode-lua';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-min-noconflict/ext-searchbox';
import 'ace-builds/src-min-noconflict/ext-language_tools';
import { Tooltip } from "@mui/material";
import { GvtButton, GvtIconButton } from "@gravity/ui-components";
import { Icon, IconsEnum } from "@gravity/icons";
import * as luaparser from 'luaparse';
import { useTranslation } from "react-i18next";
import styled from "styled-components";

window.ace.define("ace/mode/lua53", ["require", "exports", "module", "ace/lib/oop", "ace/mode/text", "ace/mode/lua_highlight_rules", "ace/mode/folding/lua", "ace/range"], function (require, exports, module) {
  "use strict";

  var oop = require("../lib/oop");
  var TextMode = require("./text").Mode;
  var LuaHighlightRules = require("./lua_highlight_rules").LuaHighlightRules;
  var LuaFoldMode = require("./folding/lua").FoldMode;
  var Range = require("../range").Range;

  var Mode = function () {
    this.HighlightRules = LuaHighlightRules;

    this.foldingRules = new LuaFoldMode();
    this.$behaviour = this.$defaultBehaviour;
  };
  oop.inherits(Mode, TextMode);

  (function () {

    this.lineCommentStart = "--";
    this.blockComment = { start: "--[", end: "]--" };

    var indentKeywords = {
      "function": 1,
      "then": 1,
      "do": 1,
      "else": 1,
      "elseif": 1,
      "repeat": 1,
      "end": -1,
      "until": -1
    };
    var outdentKeywords = [
      "else",
      "elseif",
      "end",
      "until"
    ];

    function getNetIndentLevel(tokens) {
      var level = 0;
      for (var i = 0; i < tokens.length; i++) {
        var token = tokens[i];
        if (token.type == "keyword") {
          if (token.value in indentKeywords) {
            level += indentKeywords[token.value];
          }
        } else if (token.type == "paren.lparen") {
          level += token.value.length;
        } else if (token.type == "paren.rparen") {
          level -= token.value.length;
        }
      }
      if (level < 0) {
        return -1;
      } else if (level > 0) {
        return 1;
      } else {
        return 0;
      }
    }

    this.getNextLineIndent = function (state, line, tab) {
      var indent = this.$getIndent(line);
      var level = 0;

      var tokenizedLine = this.getTokenizer().getLineTokens(line, state);
      var tokens = tokenizedLine.tokens;

      if (state == "start") {
        level = getNetIndentLevel(tokens);
      }
      if (level > 0) {
        return indent + tab;
      } else if (level < 0 && indent.substr(indent.length - tab.length) == tab) {
        if (!this.checkOutdent(state, line, "\n")) {
          return indent.substr(0, indent.length - tab.length);
        }
      }
      return indent;
    };

    this.checkOutdent = function (state, line, input) {
      if (input != "\n" && input != "\r" && input != "\r\n")
        return false;

      if (line.match(/^\s*[\)\}\]]$/))
        return true;

      var tokens = this.getTokenizer().getLineTokens(line.trim(), state).tokens;

      if (!tokens || !tokens.length)
        return false;

      return (tokens[0].type == "keyword" && outdentKeywords.indexOf(tokens[0].value) != -1);
    };

    this.getMatching = function (session, row, column) {
      if (row == undefined) {
        var pos = session.selection.lead;
        column = pos.column;
        row = pos.row;
      }

      var startToken = session.getTokenAt(row, column);
      if (startToken && startToken.value in indentKeywords)
        return this.foldingRules.luaBlock(session, row, column, true);
    };

    this.autoOutdent = function (state, session, row) {
      var line = session.getLine(row);
      var column = line.match(/^\s*/)[0].length;
      if (!column || !row) return;

      var startRange = this.getMatching(session, row, column + 1);
      if (!startRange || startRange.start.row == row)
        return;
      var indent = this.$getIndent(session.getLine(startRange.start.row));
      if (indent.length != column) {
        session.replace(new Range(row, 0, row, column), indent);
        session.outdentRows(new Range(row + 1, 0, row + 1, 0));
      }
    };

    this.$id = "ace/mode/lua53";
    this.snippetFileId = "ace/snippets/lua";
  }).call(Mode.prototype);

  exports.Mode = Mode;
}); (function () {
  window.ace.require(["ace/mode/lua53"], function (m) {
    if (typeof module == "object" && typeof exports == "object" && module) {
      module.exports = m;
    }
  });
})();

const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--chart-category-other);
`;
const StyledInnerHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  > *:first-child {
    margin-left: 5px;
  }

  > div {
    display: flex;
  }
`;

const StyledAceEditor = styled.div`
  .ace-error {
    #ace-editor {
      background-color: var(--validation-error) !important;
    }
  }
`;

const deletedKey = 'DELETED';

export const LuaEditor = forwardRef(({
  onChange = () => { },
  file,
  content,
  readOnly = false,
  error = false,
  errorOnCode = false,
  hideName = false
}, ref) => {
  const { t } = useTranslation(['appCreation', 'common']);
  const [luaEditor, setLuaEditor] = useState(null);
  const [luaFile, setLuaFile] = useState(file);
  const [luaFileContent, setLuaFileContent] = useState(content);
  const [luaFileStatus, setLuaFileStatus] = useState('none');
  const [annotations, setAnnotations] = useState([]);

  useImperativeHandle(ref, () => ({
    validateCode() {
      return annotations.length <= 0;
    }
  }));

  useEffect(() => {
    setLuaFile(file)
  }, [file])

  useEffect(() => {
    setLuaFileContent(content)
    if (!readOnly) {
      validateLua(content, luaFile, false);
    }
  }, [content])

  useEffect(() => {
    if (luaFile == deletedKey && luaFileContent == '') {
      luaEditor.setValue('');
    }
  }, [luaFile, luaFileContent])

  const onLoadAceEditor = (editor) => {
    setLuaEditor(editor);
    editor.commands.removeCommand("showSettingsMenu");
    editor.commands.addCommand({
      name: "showKeyboardShortcuts",
      bindKey: { win: "Ctrl-Alt-h", mac: "Command-Alt-h" },
      exec: function (editor) {
        window.ace.config.loadModule("ace/ext/keybinding_menu", function (module) {
          module.init(editor);
          editor.showKeyboardShortcuts()
        })
      }
    })
  }

  const reader = new FileReader();
  reader.onload = function (fileEvent) {
    luaEditor.setValue(fileEvent.target.result);
    setLuaFileContent(fileEvent.target.result);
    validateLua(fileEvent.target.result, fileEvent.target.fileInfo)
  }

  const onDropLuaFile = (event) => {
    event.stopPropagation();
    event.preventDefault();
    parseFiles(event.dataTransfer.files)
  }

  const parseFiles = (files) => {
    if (files && files[0]) {
      reader.fileInfo = files[0];
      reader.readAsText(files[0]);
      setLuaFile(files[0]);
    }
  }

  const validateLua = (value, file, doChange = true) => {
    let errors = [];
    let luaVal = value ? value : (luaEditor && luaEditor.session ? luaEditor.session.getValue() : '')
    try {
      luaparser.parse(luaVal, {
        wait: false, comments: true, scope: false, locations: false, ranges: false, onCreateNode: null,
        onCreateScope: null, onDestroyScope: null, onLocalDeclaration: null, luaVersion: '5.3', encodingMode: 'none'
      });
    } catch (e) {
      if (e instanceof luaparser.SyntaxError) {
        errors.push({
          row: e.line - 1,
          column: e.column,
          text: e.message,
          type: "error"
        });
      }
    }
    setAnnotations(errors);
    setLuaFileStatus(errors.length > 0 ? 'critical' : 'ok');
    if (doChange) {
      onChange(value, (file == deletedKey ? {} : file), errors.length > 0);
    }
  }

  const deleteFile = () => {
    setLuaFile(deletedKey);
    setLuaFileContent('')
  }

  return (
    <>
      {readOnly && (
        <div>
          <AceEditor
            mode="lua53"
            theme="github"
            id="luaAceEditorOverview"
            fontSize={14}
            readOnly
            width='100%'
            height="300px"
            setOptions={{
              showLineNumbers: true,
              tabSize: 4,
            }}
            wrapEnabled={true}
            value={luaFileContent}
          />
        </div>
      )}
      {!readOnly && (
        <StyledAceEditor
          onDragOver={
            (e) => {
              e.stopPropagation();
              e.preventDefault();
              e.dataTransfer.dropEffect = 'copy';
            }}
          onDrop={onDropLuaFile}>
          <StyledHeader>
            <StyledInnerHeader>
              {luaFile && luaFile.size > 0 && !hideName && (<div style={{ display: 'flex', paddingRight: '5px', paddingLeft: '5px' }}>{luaFile ? luaFile.name : ''}</div>)}
              {luaFile && luaFile.size > 0 && (<GvtIconButton onClick={() => { deleteFile() }}>
                <Icon name={IconsEnum.CoreTrash} />
              </GvtIconButton>)}
              {!error && !errorOnCode && luaFileStatus === 'ok' && luaFileContent && luaFileContent.length > 0 && (<Tooltip title={t('fields.lua_script.status.ok')}>
                <div><Icon name={IconsEnum.HealthLargeGood} /></div>
              </Tooltip>)}
              {(error || errorOnCode || (luaFileStatus === 'critical' && luaFileContent && luaFileContent.length > 0)) && (<Tooltip title={t('fields.lua_script.status.critical')}>
                <div><Icon name={IconsEnum.HealthLargePoor} /></div>
              </Tooltip>)}
            </StyledInnerHeader>
            <div style={{ display: 'none' }}>
              <input type="file"
                id="luaFileInput"
                accept=".lua"
                onChange={event => {
                  parseFiles(event.target.files);
                }}
              />
            </div>
            <StyledInnerHeader>
              <Tooltip title={t('search', { ns: 'common' })}>
                <div>
                  <GvtIconButton onClick={() => { luaEditor.commands.commands.find.exec(luaEditor) }}>
                    <Icon name={IconsEnum.CoreSearch} />
                  </GvtIconButton>
                </div>
              </Tooltip>
              <GvtButton style={{ paddingRight: '5px' }} onClick={() => document.getElementById('luaFileInput').click()} startIcon={<Icon name={IconsEnum.CoreUpload} />}>
                {t('import', { ns: 'common' })}
              </GvtButton>
              <Tooltip title={t('fields.lua_script.shortcuts')}>
                <div>
                  <GvtIconButton onClick={() => { luaEditor.commands.commands.showKeyboardShortcuts.exec(luaEditor) }}>
                    <Icon name={IconsEnum.CoreHelp} />
                  </GvtIconButton>
                </div>
              </Tooltip>
            </StyledInnerHeader>
          </StyledHeader>
          <div className={error && !errorOnCode ? "ace-error" : ""}>
            <AceEditor
              mode="lua53"
              theme="github"
              id="luaAceEditor"
              onChange={function (value) {
                setLuaFileContent(value);
                validateLua(value, luaFile);
              }}
              wrapEnabled={true}
              fontSize={14}
              showPrintMargin
              showGutter
              highlightActiveLine
              width='100%'
              height="300px"
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: false,
                showLineNumbers: true,
                tabSize: 4
              }}
              onLoad={onLoadAceEditor}
              annotations={annotations}
              defaultValue={luaFileContent}
              value={luaFileContent}
            />
          </div>
        </StyledAceEditor>
      )}
    </>
  )
})

export default LuaEditor;