import { Icon, IconsEnum } from "@gravity/icons";
import { DecoratedFormControl, GvtButton, GvtIconButton, GvtInput, GvtMenuItem, GvtModalDialog, GvtSelect, SimpleTable, Toggle } from "@gravity/ui-components";
import _ from "lodash";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledColumn, StyledSimpleTable, StyledSimpleTableAdd, StyledToggle } from "../styled-components";
import { ZigbeeConfigEnpointValuesEnum, ZigbeeConfigFieldEnum, ZigbeeConfigTypesEnum } from "../enums";


export const ZigbeeConfigTable = forwardRef(({
  zigbeeConfig = [],
  error = false,
  onChange = () => { },
  readOnly = false,
  disableEdit = false
}, ref) => {
  const { t } = useTranslation(['appCreation', "common"]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(-1);

  const configTypes = [
    { name: t('fields.zigbee_config.fields.config_type.values.north_bound'), id: ZigbeeConfigTypesEnum.northbound },
    { name: t('fields.zigbee_config.fields.config_type.values.south_bound'), id: ZigbeeConfigTypesEnum.southbound }
  ]

  const [configType, setConfigType] = useState(ZigbeeConfigTypesEnum.northbound);
  const [configTypeError, setConfigTypeError] = useState('');
  const [srcEndpoint, setSrcEndpoint] = useState(ZigbeeConfigEnpointValuesEnum.min);
  const [srcEndpointError, setSrcEndpointError] = useState('');
  const [destEndpoint, setDestEndpoint] = useState(ZigbeeConfigEnpointValuesEnum.min);
  const [destEndpointError, setDestEndpointError] = useState('');
  const [profileId, setProfileId] = useState("");
  const [profileIdError, setProfileIdError] = useState('');
  const [clusterId, setClusterId] = useState("");
  const [clusterIdError, setClusterIdError] = useState('');
  const [apsAck, setApsAck] = useState(false);

  useImperativeHandle(ref, () => ({
    validateConfig() {
      for (let i = 0; i < zigbeeConfig.length; i++) {
        if (validateConfigType(zigbeeConfig[i][ZigbeeConfigFieldEnum.config_type]) != '' ||
          validateSrcEndpoint(zigbeeConfig[i][ZigbeeConfigFieldEnum.src_endpoint]) != '' ||
          validateDestEndpoint(zigbeeConfig[i][ZigbeeConfigFieldEnum.dest_endpoint]) != '' ||
          validateProfileId(zigbeeConfig[i][ZigbeeConfigFieldEnum.profile_id]) != '' ||
          validateClusterId(zigbeeConfig[i][ZigbeeConfigFieldEnum.cluster_id]) != '') {
          return false;
        }
      }
      return true;
    }
  }));

  const validateAll = () => {
    let arr = [];
    arr.push(validateConfigType(configType))
    arr.push(validateSrcEndpoint(srcEndpoint))
    arr.push(validateDestEndpoint(destEndpoint))
    arr.push(validateProfileId(profileId))
    arr.push(validateClusterId(clusterId))
    arr = [...new Set(arr)];
    return !(arr.length > 1 || (arr.length == 1 && arr[0] != ''));
  }

  const changeConfigType = (e) => {
    removeDuplicatedError();
    let value = e.target.value
    validateConfigType(value);
    setConfigType(value);
    setApsAck(false);
  }

  const validateConfigType = (value) => {
    let errorType = !value ? 'required' : '';
    setConfigTypeError(errorType);
    return errorType;
  }

  const changeSrcEndpoint = (e) => {
    removeDuplicatedError();
    validateSrcEndpoint(e.target.value);
    setSrcEndpoint(e.target.value);
  }

  const validateSrcEndpoint = (value) => {
    let errorType = validateEndpoint(Number(value));
    setSrcEndpointError(errorType);
    return errorType;
  }

  const changeDestEndpoint = (e) => {
    removeDuplicatedError();
    validateDestEndpoint(e.target.value);
    setDestEndpoint(e.target.value);
  }

  const validateDestEndpoint = (value) => {
    let errorType = validateEndpoint(Number(value));
    setDestEndpointError(errorType);
    return errorType;
  }

  const validateEndpoint = (value) => {
    if (isNaN(value)) {
      return 'required';
    } else if (value < ZigbeeConfigEnpointValuesEnum.min) {
      return 'min';
    } else if (value > ZigbeeConfigEnpointValuesEnum.max) {
      return 'max';
    } else if (!Number.isInteger(value)) {
      return 'invalid';
    }
    return '';
  }

  const changeProfileId = (e) => {
    removeDuplicatedError();
    let value = e.target.value;
    validateProfileId(value);
    setProfileId(value);
  }

  const validateProfileId = (value) => {
    let validRange = /^[0-7C-Fc-f]{0,1}[0-9A-Fa-f]{1,3}$/g.test(value);
    let errorType = !value ? 'required' : (!validRange ? 'range' : '');
    setProfileIdError(errorType);
    return errorType;
  }

  const changeClusterId = (e) => {
    removeDuplicatedError();
    let value = e.target.value;
    validateClusterId(value);
    setClusterId(value);
  }

  const validateClusterId = (value) => {
    let validRange = /^([0-7]{0,1}[0-9A-Fa-f]{1,3}|[Ff][C-Fc-f][0-9A-Fa-f]{2})$/g.test(value)
    let errorType = !value ? 'required' : (!validRange ? 'range' : '');
    setClusterIdError(errorType);
    return errorType;
  }

  const changeApsAck = (e) => {
    let value = e.target.checked;
    setApsAck(value);
    removeDuplicatedError();
  }

  const getMessage = (key, value) => {
    if (value != '') {
      return t('fields.zigbee_config.fields.' + key + '.error.' + value)
    }
    return t('fields.zigbee_config.fields.' + key + '.help')
  }


  const openModal = () => {
    setDialogOpen(true);
    setConfigType(ZigbeeConfigTypesEnum.northbound);
    setSrcEndpoint(ZigbeeConfigEnpointValuesEnum.min);
    setDestEndpoint(ZigbeeConfigEnpointValuesEnum.min);
    setProfileId("");
    setClusterId("");
    setApsAck(false);
    clearError();
    setEditIndex(-1);
  }

  const clearError = () => {
    setConfigTypeError('');
    setSrcEndpointError('');
    setDestEndpointError('');
    setProfileIdError('');
    setClusterIdError('');
  }

  const setDuplicatedError = () => {
    let key = 'duplicated';
    setConfigTypeError(key);
    setSrcEndpointError(key);
    setDestEndpointError(key);
    setProfileIdError(key);
    setClusterIdError(key);
  }

  const removeDuplicatedError = () => {
    let key = 'duplicated';
    if (configTypeError == key) {
      setConfigTypeError('');
    }
    if (srcEndpointError == key) {
      setSrcEndpointError('');
    }
    if (destEndpointError == key) {
      setDestEndpointError('');
    }
    if (profileIdError == key) {
      setProfileIdError('');
    }
    if (clusterIdError == key) {
      setClusterIdError('');
    }
  }

  const addZigbeeConfig = (configType, srcEndpoint, destEndpoint, profileId, clusterId, apsAck) => {
    let zigbeeConfigList = _.cloneDeep(zigbeeConfig);
    let duplicated = -1
    let newConfig = {
      [ZigbeeConfigFieldEnum.config_type]: configType,
      [ZigbeeConfigFieldEnum.src_endpoint]: srcEndpoint,
      [ZigbeeConfigFieldEnum.dest_endpoint]: destEndpoint,
      [ZigbeeConfigFieldEnum.profile_id]: profileId,
      [ZigbeeConfigFieldEnum.cluster_id]: clusterId,
      [ZigbeeConfigFieldEnum.aps_ack]: apsAck,
    }
    for (let i = 0; i < zigbeeConfigList.length; i++) {
      if (_.isEqual(newConfig, zigbeeConfigList[i])) {
        duplicated = i;
        break;
      }
    }
    if (duplicated < 0 && editIndex == -1) {
      zigbeeConfigList.push(newConfig);
      onChange(zigbeeConfigList);
      setDialogOpen(false);
    } else if (editIndex >= 0) {
      zigbeeConfigList[editIndex] = newConfig;
      onChange(zigbeeConfigList);
      setDialogOpen(false);
    } else {
      setDuplicatedError();
    }
  }

  const removeZigbeeConfig = (value) => {
    let zigbeeConfigList = _.cloneDeep(zigbeeConfig);
    for (let i = 0; i < zigbeeConfigList.length; i++) {
      if (_.isEqual(value, zigbeeConfigList[i])) {
        zigbeeConfigList.splice(i, 1);
        break;
      }
    }
    onChange(zigbeeConfigList);
  }

  const editZigbeeConfig = (value) => {
    setDialogOpen(true);
    for (let i = 0; i < zigbeeConfig.length; i++) {
      if (_.isEqual(value, zigbeeConfig[i])) {
        setEditIndex(i);
        break;
      }
    }
    setConfigType(value[ZigbeeConfigFieldEnum.config_type]);
    setSrcEndpoint(value[ZigbeeConfigFieldEnum.src_endpoint]);
    setDestEndpoint(value[ZigbeeConfigFieldEnum.dest_endpoint]);
    setProfileId(value[ZigbeeConfigFieldEnum.profile_id]);
    setClusterId(value[ZigbeeConfigFieldEnum.cluster_id]);
    setApsAck(value[ZigbeeConfigFieldEnum.aps_ack]);
    clearError();
  }

  return (
    <>
      <StyledSimpleTable>
        {!readOnly && !disableEdit && zigbeeConfig.length > 0 && (<StyledSimpleTableAdd>
          <GvtIconButton onClick={() => { openModal() }} disabled={dialogOpen}>
            <Icon name={IconsEnum.FormAdd} />
          </GvtIconButton>
        </StyledSimpleTableAdd>)}
        <div className={error ? (zigbeeConfig.length <= 0 && !readOnly ? "has-error-self" : "has-error") : ""}>
          {zigbeeConfig.length > 0 && (<SimpleTable columns={[
            {
              field: ZigbeeConfigFieldEnum.config_type,
              displayName: t('fields.zigbee_config.fields.config_type.label'),
              cellFormatter: (rowData, value) => {
                return (
                  <>
                    {t('fields.zigbee_config.fields.config_type.values.' + value.toLowerCase())}
                  </>
                )
              }
            },
            {
              field: ZigbeeConfigFieldEnum.src_endpoint,
              displayName: t('fields.zigbee_config.fields.src_endpoint.label')
            },
            {
              field: ZigbeeConfigFieldEnum.dest_endpoint,
              displayName: t('fields.zigbee_config.fields.dest_endpoint.label')
            },
            {
              field: ZigbeeConfigFieldEnum.profile_id,
              displayName: t('fields.zigbee_config.fields.profile_id.label'),
              cellFormatter: (rowData, value) => {
                return (
                  <>{"0x" + value}</>
                )
              }
            },
            {
              field: ZigbeeConfigFieldEnum.cluster_id,
              displayName: t('fields.zigbee_config.fields.cluster_id.label'),
              cellFormatter: (rowData, value) => {
                return (<>{"0x" + value}</>)
              }
            },
            {
              field: ZigbeeConfigFieldEnum.aps_ack,
              displayName: t('fields.zigbee_config.fields.aps_ack.label'),
              cellFormatter: (rowData, value) => {
                return (
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {rowData[ZigbeeConfigFieldEnum.config_type] == ZigbeeConfigTypesEnum.northbound ? "-" : (value ? t('yes', { ns: "common" }) : t('no', { ns: "common" }))}
                    {!disableEdit && (<div>
                      <GvtIconButton onClick={() => { editZigbeeConfig(rowData) }}>
                        <Icon name={IconsEnum.FormEdit} />
                      </GvtIconButton>
                      <GvtIconButton onClick={() => { removeZigbeeConfig(rowData) }}>
                        <Icon name={IconsEnum.FormTrash} />
                      </GvtIconButton>
                    </div>)}
                  </div>
                )
              }
            }
          ]} rows={zigbeeConfig} rowsRestriction={zigbeeConfig.length + 1} />)}
          {zigbeeConfig.length <= 0 && !readOnly && (<GvtButton color='secondary' onClick={() => openModal()} disabled={dialogOpen}>{t('fields.zigbee_config.new.title')}</GvtButton>)}
          {zigbeeConfig.length <= 0 && readOnly && (<div>{t('fields.zigbee_config.no_data')}</div>)}
        </div>
      </StyledSimpleTable>
      <GvtModalDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onBackdropClick={() => setDialogOpen(false)}
        title={t('fields.zigbee_config.new.title')}
        buttons={[
          {
            onClick: () => setDialogOpen(false),
            children: t('cancel', { ns: "common" })
          },
          {
            onClick: () => {
              if (validateAll()) {
                addZigbeeConfig(configType, srcEndpoint, destEndpoint, profileId, clusterId, apsAck);
              }
            },
            color: 'primary',
            children: t('save', { ns: "common" })
          }
        ]}
      >
        <StyledColumn>
          <DecoratedFormControl required={true} label={t('fields.zigbee_config.fields.config_type.label')} description={t('fields.zigbee_config.fields.config_type.description')} error={configTypeError != ''} info={getMessage(ZigbeeConfigFieldEnum.config_type, configTypeError)}>
            <GvtSelect onChange={changeConfigType} value={configType}>
              {configTypes.map((config, index) => (
                <GvtMenuItem key={'config' + index} value={config.id}>{config.name}</GvtMenuItem>
              ))}
            </GvtSelect>
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.zigbee_config.fields.src_endpoint.label')} description={t('fields.zigbee_config.fields.src_endpoint.description')} error={srcEndpointError != ''} info={getMessage(ZigbeeConfigFieldEnum.src_endpoint, srcEndpointError)}>
            <GvtInput onChange={changeSrcEndpoint} value={srcEndpoint} type="number" inputProps={{ min: ZigbeeConfigEnpointValuesEnum.min, max: ZigbeeConfigEnpointValuesEnum.max, step: 1 }} />
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.zigbee_config.fields.dest_endpoint.label')} description={t('fields.zigbee_config.fields.dest_endpoint.description')} error={destEndpointError != ''} info={getMessage(ZigbeeConfigFieldEnum.dest_endpoint, destEndpointError)}>
            <GvtInput onChange={changeDestEndpoint} value={destEndpoint} type="number" inputProps={{ min: ZigbeeConfigEnpointValuesEnum.min, max: ZigbeeConfigEnpointValuesEnum.max, step: 1 }} />
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.zigbee_config.fields.profile_id.label')} description={t('fields.zigbee_config.fields.profile_id.description')} error={profileIdError != ''} info={getMessage(ZigbeeConfigFieldEnum.profile_id, profileIdError)}>
            <GvtInput onBlur={(e) => { setProfileId(e.target.value.padStart(4, "0").toUpperCase()) }} onChange={changeProfileId} value={profileId} inputProps={{ maxLength: 4 }} startAdornment={<>0x</>} />
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.zigbee_config.fields.cluster_id.label')} description={t('fields.zigbee_config.fields.cluster_id.description')} error={clusterIdError != ''} info={getMessage(ZigbeeConfigFieldEnum.cluster_id, clusterIdError)}>
            <GvtInput onBlur={(e) => { setClusterId(e.target.value.padStart(4, "0").toUpperCase()) }} onChange={changeClusterId} value={clusterId} inputProps={{ maxLength: 4 }} startAdornment={<>0x</>} />
          </DecoratedFormControl>
          {configType == ZigbeeConfigTypesEnum.southbound && (
            <DecoratedFormControl>
              <StyledToggle description={t('fields.zigbee_config.fields.aps_ack.description')}>
                <Toggle label={t('fields.zigbee_config.fields.aps_ack.label')} onChange={changeApsAck} checked={apsAck} info={getMessage(ZigbeeConfigFieldEnum.aps_ack)} />
              </StyledToggle>
            </DecoratedFormControl>
          )}
        </StyledColumn>
      </GvtModalDialog>
    </>
  )
})

export default ZigbeeConfigTable;