import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getApps } from '../../utils/apis';
import { FieldTypesEnum,GvtTypography, TableView, ViewModeEnum } from '@gravity/ui-components';
import styled from 'styled-components';
import { IconsEnum } from '@gravity/icons';
import { appRedirectRoute } from '../../utils/routes';
import { usePendingContext } from '../../context/adp-context/context';
import AppsActionModal from './apps.action.modal';
import SecurityDetailsModal from './security.details.modal';

const AppsPage = () => {

  const { t } = useTranslation(['apps', 'common']);
  const grid = useRef(null);

  const dialogRef = useRef();
  const { pendingRef } = usePendingContext();

  const [modalOpen, setModalOpen] = useState(false);
  const [appId, setAppId] = useState(null);
  const [appVersion, setAppVersion] = useState(null);

  const fetchGridData = (paginationState, searchValue, quickFilters, sorting, filters) => {
    let parsedSort = {}
    if ('fields' in sorting && sorting['fields'].length > 0) {
      for (let i = 0; i < sorting['fields'].length; i++) {
        parsedSort[sorting['fields'][i]['field']] = sorting['fields'][i]['order'].toLowerCase() === 'asc' ? 1 : -1;
      }
    }

    let parsedFilter = {};
    if(pendingRef.current) {
      parsedFilter['status'] = [AppStatus.PENDING_DRAFT];
      parsedFilter['status'].push(AppStatus.IN_REVIEW);
    }

    let currentFilter = filters.filters.filters && filters.filters.filters.length >= 1 && 'filters' in filters.filters.filters[0] ? filters.filters.filters[0]['filters'] : filters.filters.filters;
    for (let i = 0; i < currentFilter.length; i++) {
      if (!(currentFilter[i]['field'] in parsedFilter)) {
        parsedFilter[currentFilter[i]['field']] = [];
      }
      parsedFilter[currentFilter[i]['field']].push(currentFilter[i]['value'])
      pendingRef.current = false;
    }

    return getApps({ 
      pageNumber: (paginationState.offset / paginationState.limit) + 1, 
      pageSize: paginationState.limit, 
      sort: parsedSort, 
      query: parsedFilter
    }).then(
      (response) => {
        return {
          rows: response.data.content,
          total: response.data.total_elements,
          totalFiltered: response.data.number_of_elements
        }
      },
      (error) => {
        console.error(error);
        return {
          rows: [],
          total: 0,
          totalFiltered: 0
        }
      }
    )
  };

  const updateApp = (action, data) => {
    if (action == 'delete') {
      grid.current.api.purgeInfiniteCache();
    } else {
      grid.current.api.forEachNode((rowNode, index) => {
        if (rowNode.data['app_id'] == data['app_id'] && rowNode.data['version'] == data['version']) {
          rowNode.setData(data);
        }
      });
    }
  }

  const StyledTableView = styled.div`
    height: 100% !important;
    .aruba-grid-container {
      height: calc(100% - 122px) !important;
    }
  `;


  const AppStatus = {
    APPROVED_APP: 'APPROVED_APP',
    DRAFT: 'DRAFT',
    PENDING_DRAFT: 'PENDING_DRAFT',
    IN_REVIEW: 'IN_REVIEW',
    REJECTED_DRAFT: 'REJECTED_DRAFT',
    SUSPENDED: 'SUSPENDED'
  }

  const gridFiltersDialogProps = {
    filters: [
      {
        displayName: t('status', { ns: 'common' }),
        field: 'status',
        fieldType: FieldTypesEnum.Enumeration,
        filterParams: {
          enumType: AppStatus,
          selectedValues: pendingRef.current ? Object.keys(AppStatus).slice(2, 4) : null,
          textFormatter: (enumKey, filter) => {
            return t('app_status.' + enumKey, { ns: 'common' })
          }
        }
      }
    ]
  };

  return (
    <>
      <StyledTableView>
        <TableView
          tableName={t('app_page.title')}
          subHeader={<GvtTypography variant="h5">{t('app_page.subtitle')}</GvtTypography>}
          gridConfig={
            {
              viewMode: ViewModeEnum.CompactView,
              fieldsCompactView: [
                {
                  field: 'app_id',
                  displayName: t('id', { ns: 'common' }),
                  sortable: true,
                  link: (rowData) => appRedirectRoute + rowData?.app_id + "/details",
                  onClick: (...args) => console.log('Row Clicked', args)
                },
                {
                  field: 'developer_id',
                  displayName: t('organization', { ns: 'common' }),
                  sortable: true,
                  fieldType: FieldTypesEnum.Text
                },
                {
                  field: 'name',
                  displayName: t('app_name', { ns: 'common' }),
                  sortable: true,
                  fieldType: FieldTypesEnum.Text
                },
                {
                  field: 'version',
                  displayName: t('version', { ns: 'common' }),
                  sortable: true,
                  fieldType: FieldTypesEnum.Text
                },
                {
                  field: 'status',
                  displayName: t('status', { ns: 'common' }),
                  valueFormatter: (params) => {
                    return params && 'status' in params && params.status ? (t('app_status.' + params?.status, { ns: 'common' })) : ''
                  },
                  sortable: true,
                  icon: (rowData) => {
                    const fieldValue = rowData?.status;
                    switch (fieldValue) {
                      case 'SUSPENDED':
                        return IconsEnum.HealthSmallUnknown;
                      case 'APPROVED_APP':
                        return IconsEnum.HealthSmallGood;
                      case 'REJECTED_DRAFT':
                        return IconsEnum.HealthSmallPoor;
                      default:
                        return IconsEnum.HealthSmallFair;
                    }
                  }
                },
                {
                  field: 'updated_at',
                  displayName: t('modified_at', { ns: 'common' }),
                  valueFormatter: (params) => {
                    return params && 'updated_at' in params && params.updated_at ? new Date(params?.updated_at).toLocaleDateString() : '';
                  },
                  sortable: true,
                  fieldType: FieldTypesEnum.Text
                },
                {
                  field: 'created_by',
                  displayName: t('created_by', { ns: 'common' }),
                  sortable: true,
                  fieldType: FieldTypesEnum.Text
                }
              ],
              fieldsComfortView: [],
              fetchGridData: fetchGridData,
              pageSize: 20,
              pageCacheLimit: 2,
              events: {
                onGridReady: (e) => { grid.current = e }
              },
              rowActions: {
                delete: true,
                onAction: (action, rowData) => {
                  if(action.id === "security_report") {
                    setAppId(rowData.app_id);
                    setAppVersion(rowData.version);
                    setModalOpen(true);
                  } else {
                    dialogRef.current.doSetAppAction(action.id, rowData);
                  }
                },
                menuItems: [
                  {
                    label: t("approve", { ns: 'common' }),
                    id: 'approve'
                  },
                  {
                    label: t("reject", { ns: 'common' }),
                    id: 'reject'
                  },
                  {
                    label: t("suspend", { ns: 'common' }),
                    id: 'suspend'
                  },
                  {
                    label: t("security_scan", { ns: 'common' }),
                    id: 'security_report'
                  }
                ]
              }
            }
          }
          columnCustomization={false}
          search={false}
          gridFiltersConfig={gridFiltersDialogProps}
        />

      </StyledTableView>

      <AppsActionModal ref={dialogRef} updateApp={updateApp} />

      <SecurityDetailsModal 
        open={modalOpen} 
        setModalOpen={setModalOpen}  
        onClose={() => setModalOpen(false)}
        appId={appId}
        appVersion={appVersion}
      />
    </>
  );
}

export default AppsPage;
