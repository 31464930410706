import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteAppVersion, putAppApprove, putAppReject, putAppReview, putAppSuspend } from '../../utils/apis';
import { GvtFormControl, GvtFormLabel, GvtInput, GvtModalDialog, Loading } from '@gravity/ui-components';

const AppsActionModal = forwardRef((props, ref) => {
    const [appActionLoading, setAppActionLoading] = useState(false);
    const [appActionStatus, setAppActionStatus] = useState(true);
    const [appActionReason, setAppActionReason] = useState("");
    const [appAction, setAppAction] = useState({
        action: null,
        data: {}
    });

    const disabledRef = useRef(false);
    const { t } = useTranslation(['apps', 'common']);

    useImperativeHandle(ref, () => ({
        doSetAppAction(type = null, data = {}) {
          setAppAction({
            action: type,
            data: data
          })
          if (type === null) {
            setAppActionReason("")
          }
        }
    }));

    const getLayerTitle = () => {
        switch (appAction.action) {
          case 'approve':
            return t('approve_app.title');
          case 'reject':
            return t('reject_app.title');
          case 'suspend':
            return t('suspend_app.title');
          case 'review':
            return t('review_app.title');
          case 'delete':
            return t('delete_app.title');
        }
    }

    const getLayerText = () => {
        let info = { name: appAction.data.name, version: appAction.data.version }
        let info2 = { state: appAction.data.status}
        switch (appAction.action) {
            case 'approve':
                if(appAction.data.status === 'PENDING_DRAFT' || appAction.data.status === 'REJECTED_DRAFT' || appAction.data.status === 'SUSPENDED') {
                disabledRef.current = false;
                return appActionStatus ? t('approve_app.confirm', info) :
                    t('approve_app.error', info);
                }
                disabledRef.current = true;
                return t('approve_app.info', info2);
            case 'reject':
                if(appAction.data.status === 'PENDING_DRAFT' || appAction.data.status === 'APPROVED_APP' || appAction.data.status === 'SUSPENDED') {
                disabledRef.current = false;
                return appActionStatus ? t('reject_app.confirm', info) :
                    t('reject_app.error', info);
                }
                disabledRef.current = true;
                return t('reject_app.info', info2);
            case 'suspend':
                if(appAction.data.status === 'APPROVED_APP') {
                disabledRef.current = false;
                return appActionStatus ? t('suspend_app.confirm', info) :
                    t('suspend_app.error', info);
                }
                disabledRef.current = true;
                return t('suspend_app.info', info2);
            case 'review':
                return appActionStatus ? t('review_app.confirm', info) :
                t('review_app.error', info);
            case 'delete':
                return appActionStatus ? t('delete_app.confirm', info) :
                t('delete_app.error', info);
        }
    }

    const handleRequest = (action, request) => {
        request.then(
            (response) => {
                props.updateApp(action, response.data);
                setAppActionLoading(false);
                setAppActionStatus(true);
                ref.current.doSetAppAction();
            },
            (error) => {
                console.error(error);
                setAppActionLoading(false);
                setAppActionStatus(false);
            }
        )
    }

    const approveApp = (data) => {
        handleRequest('approve', putAppApprove({ app_id: data.app_id, version: data.version, reason: appActionReason }))
    }

    const rejectApp = (data) => {
        handleRequest('reject', putAppReject({ app_id: data.app_id, version: data.version, reason: appActionReason }))
    }
    
    const suspendApp = (data) => {
        handleRequest('suspend', putAppSuspend({ app_id: data.app_id, version: data.version, reason: appActionReason }))
    }
    
    const reviewApp = (data) => {
        handleRequest('review', putAppReview({ app_id: data.app_id, version: data.version, reason: appActionReason }))
    }

    const deleteApp = (data) => {
        deleteAppVersion({ app_id: data.app_id, version: data.version, developer_id: data.developer_id }).then(
            (response) => {
                setAppActionLoading(false);
                setAppActionStatus(true);
                ref.current.doSetAppAction();
                props.updateApp('delete', response.data);
            },
            (error) => {
                console.error(error);
                setAppActionLoading(false);
                setAppActionStatus(false);
            }
        )
    }

    const doAppAction = (actionType, actionData) => {
        setAppActionLoading(true);
        if (actionType === 'approve') {
            approveApp(actionData);
        } else if (actionType === 'reject') {
            rejectApp(actionData);
        } else if (actionType === 'suspend') {
            suspendApp(actionData);
        } else if (actionType === 'review') {
            reviewApp(actionData);
        } else if (actionType === 'delete') {
            deleteApp(actionData);
        }
    }

    return (
        <GvtModalDialog
            open={appAction.action != null}
            onBackdropClick={() => ref.current.doSetAppAction()}
            onClose={() => ref.current.doSetAppAction()}
            title={getLayerTitle()}
            description={getLayerText()}
            buttons={
                [{
                  onClick: () => { ref.current.doSetAppAction() },
                  children: t('cancel', { ns: 'common' }),
                  disabled: appActionLoading
                }, {
                  onClick: () => { doAppAction(appAction.action, appAction.data) },
                  color: 'primary',
                  children: appActionStatus ? t('confirm', { ns: 'common' }) : t('retry', { ns: 'common' }),
                  disabled: appActionLoading || disabledRef.current
                }]
            }
        >
            {!appActionLoading && !disabledRef.current && appAction.action && appAction.action !== 'delete' && (
                <GvtFormControl>
                    <GvtFormLabel htmlFor='reason-input'>{t('reason_for_status_change')}</GvtFormLabel>
                    <GvtInput id='reason-input'
                        onChange={(e) => {
                            setAppActionReason(e.target.value)
                        }}
                    />
                </GvtFormControl>
            )}
            {appActionLoading && (
                <Loading />
            )}
        </GvtModalDialog>
    )
})

export default AppsActionModal;