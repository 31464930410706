import { Icon, IconsEnum } from "@gravity/icons";
import { DecoratedFormControl, GvtButton, GvtIconButton, GvtInput, GvtModalDialog, SimpleTable } from "@gravity/ui-components";
import _ from "lodash";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledColumn, StyledSimpleTable, StyledSimpleTableAdd } from "../styled-components";
import { UsbDevicesDescriptorListFieldEnum } from "../enums";
import { IMaskInput } from "react-imask";

const BcdCodedVersionMask = forwardRef(function BcdCodedVersionMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="x.y"
      blocks={{
        x: {
          mask: /([0-9]|[1-9][0-9])/
        },
        y: {
          mask: '00',
          from: 1,
          to: 99
        }
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
})


export const DescriptorTable = forwardRef(({
  descriptors = [],
  error = false,
  onChange = () => { },
  readOnly = false,
  onAddNew = () => { },
  onAction = () => { },
  disableEdit = false
}, ref) => {
  const { t } = useTranslation(['appCreation', "common"]);
  const [openNew, setOpenNew] = useState(false);

  const [productId, setProductId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [manufacture, setManufacture] = useState("");
  const [product, setProduct] = useState("");
  const [bcdDevice, setBcdDevice] = useState("");
  const [version, setVersion] = useState("");
  const [editIndex, setEditIndex] = useState(-1);

  const [productIdError, setProductIdError] = useState("");
  const [vendorIdError, setVendorIdError] = useState("");
  const [manufactureError, setManufactureError] = useState("");
  const [productError, setProductError] = useState("");
  const [bcdDeviceError, setBcdDeviceError] = useState("");
  const [versionError, setVersionError] = useState("");

  useImperativeHandle(ref, () => ({
    validateDescriptors(value = descriptors) {
      for (let i = 0; i < value.length; i++) {
        if (validateProductId(value[i][UsbDevicesDescriptorListFieldEnum.product_id]) != '' ||
          validateVendorId(value[i][UsbDevicesDescriptorListFieldEnum.vendor_id]) != '' ||
          validateManufacture(value[i][UsbDevicesDescriptorListFieldEnum.manufacture]) != '' ||
          validateProduct(value[i][UsbDevicesDescriptorListFieldEnum.product_description]) != '' ||
          validateBcdDevice(value[i][UsbDevicesDescriptorListFieldEnum.device_revision]) != '' ||
          validateVersion(value[i][UsbDevicesDescriptorListFieldEnum.usb_version]) != '') {
          return false;
        }
      }
      return true;
    }
  }));

  const validateAll = () => {
    let arr = [validateProductId(productId), validateVendorId(vendorId), validateManufacture(manufacture), validateProduct(product), validateBcdDevice(bcdDevice), validateVersion(version)]
    arr = [...new Set(arr)];
    return !(arr.length > 1 || (arr.length == 1 && arr[0] != ''));
  }

  const changeProductId = (e) => {
    removeDuplicatedError();
    let value = e.target.value
    validateProductId(value);
    setProductId(value);
  }

  const validateProductId = (value) => {
    let errorType = !value ? 'required' : '';
    if (errorType == '') {
      errorType = !(/^([0-9A-Fa-f]{0,4})$/g.test(value)) ? 'range' : '';
    }
    setProductIdError(errorType)
    return errorType;
  }

  const changeVendorId = (e) => {
    removeDuplicatedError();
    let value = e.target.value
    validateVendorId(value);
    setVendorId(value);
  }

  const validateVendorId = (value) => {
    let errorType = !value ? 'required' : '';
    if (errorType == '') {
      errorType = !(/^([0-9A-Fa-f]{0,4})$/g.test(value)) ? 'range' : '';
    }
    setVendorIdError(errorType)
    return errorType;
  }

  const changeManufacture = (e) => {
    removeDuplicatedError();
    let value = e.target.value
    validateManufacture(value);
    setManufacture(value);
  }

  const validateManufacture = (value) => {
    let errorType = !value ? 'required' : '';
    setManufactureError(errorType)
    return errorType;
  }

  const changeProduct = (e) => {
    removeDuplicatedError();
    let value = e.target.value
    validateProduct(value);
    setProduct(value);
  }

  const validateProduct = (value) => {
    let errorType = !value ? 'required' : '';
    setProductError(errorType)
    return errorType;
  }

  const changeBcdDevice = (e) => {
    removeDuplicatedError();
    let value = e.target.value
    validateBcdDevice(value);
    setBcdDevice(value);
  }

  const validateBcdDevice = (value) => {
    let errorType = !value ? 'required' : '';
    if (errorType == '') {
      errorType = !(/^([0-9]|[1-9][0-9])\.[0-9][0-9]$/g.test(value)) ? 'bcd' : '';
    }
    setBcdDeviceError(errorType)
    return errorType;
  }

  const changeVersion = (e) => {
    removeDuplicatedError();
    let value = e.target.value
    validateVersion(value);
    setVersion(value);
  }

  const validateVersion = (value) => {
    let errorType = !value ? 'required' : '';
    if (errorType == '') {
      errorType = !(/^([0-9]|[1-9][0-9])\.[0-9][0-9]$/g.test(value)) ? 'bcd' : '';
    }
    setVersionError(errorType)
    return errorType;
  }

  const getMessage = (key, value) => {
    if (value != '') {
      return t('fields.usb_config.fields.descriptors.fields.' + key + '.error.' + value)
    }
    return t('fields.usb_config.fields.descriptors.fields.' + key + '.help')
  }


  const openNewDescriptor = () => {
    onAction();
    setOpenNew(true);
    setProductId("");
    setVendorId("");
    setManufacture("");
    setProduct("");
    setBcdDevice("");
    setVersion("");
    clearError();
    onAddNew(true);
    setEditIndex(-1);
  }

  const clearError = () => {
    setProductIdError("");
    setVendorIdError("");
    setManufactureError("");
    setProductError("");
    setBcdDeviceError("");
    setVersionError("");
  }

  const setDuplicatedError = () => {
    let key = 'duplicated';
    setProductIdError(key);
    setVendorIdError(key);
    setManufactureError(key);
    setProductError(key);
    setBcdDeviceError(key);
    setVersionError(key);
  }

  const removeDuplicatedError = () => {
    let key = 'duplicated';
    if (productIdError == key) {
      setProductIdError('');
    }
    if (vendorIdError == key) {
      setVendorIdError('');
    }
    if (manufactureError == key) {
      setManufactureError('');
    }
    if (productError == key) {
      setProductError('');
    }
    if (bcdDeviceError == key) {
      setBcdDeviceError('');
    }
    if (versionError == key) {
      setVersionError('');
    }
  }

  const addNew = () => {
    let list = _.cloneDeep(descriptors);
    let duplicated = -1
    let newConfig = {
      [UsbDevicesDescriptorListFieldEnum.product_id]: productId,
      [UsbDevicesDescriptorListFieldEnum.vendor_id]: vendorId,
      [UsbDevicesDescriptorListFieldEnum.manufacture]: manufacture,
      [UsbDevicesDescriptorListFieldEnum.product_description]: product,
      [UsbDevicesDescriptorListFieldEnum.device_revision]: bcdDevice,
      [UsbDevicesDescriptorListFieldEnum.usb_version]: version
    }
    for (let i = 0; i < list.length; i++) {
      if (_.isEqual(newConfig, list[i])) {
        duplicated = i;
        break;
      }
    }
    if (duplicated == -1 && editIndex == -1) {
      list.push(newConfig);
      onChange(list);
      onAddNew(false);
      setOpenNew(false);
    } else if (editIndex >= 0) {
      list[editIndex] = newConfig;
      onChange(list);
      onAddNew(false);
      setOpenNew(false);
    } else {
      setDuplicatedError();
    }
  }

  const remove = (value) => {
    onAction();
    let list = _.cloneDeep(descriptors);
    for (let i = 0; i < list.length; i++) {
      if (_.isEqual(value, list[i])) {
        list.splice(i, 1);
        break;
      }
    }
    onChange(list);
  }

  const edit = (value) => {
    onAction();
    setOpenNew(true);
    for (let i = 0; i < descriptors.length; i++) {
      if (_.isEqual(value, descriptors[i])) {
        setEditIndex(i);
        break;
      }
    }
    setProductId(value[UsbDevicesDescriptorListFieldEnum.product_id]);
    setVendorId(value[UsbDevicesDescriptorListFieldEnum.vendor_id]);
    setManufacture(value[UsbDevicesDescriptorListFieldEnum.manufacture]);
    setProduct(value[UsbDevicesDescriptorListFieldEnum.product_description]);
    setBcdDevice(value[UsbDevicesDescriptorListFieldEnum.device_revision]);
    setVersion(value[UsbDevicesDescriptorListFieldEnum.usb_version]);
    clearError();
    onAddNew(true);
  }

  return (
    <>
      {descriptors.length > 0 && (<StyledSimpleTable>
        {!readOnly && !disableEdit && (<StyledSimpleTableAdd>
          <GvtIconButton onClick={() => { openNewDescriptor() }} disabled={openNew}>
            <Icon name={IconsEnum.FormAdd} />
          </GvtIconButton>
        </StyledSimpleTableAdd>)}
        <div className={error ? "has-error" : ""}>
          <SimpleTable columns={[
            {
              field: UsbDevicesDescriptorListFieldEnum.product_id,
              displayName: t('fields.usb_config.fields.descriptors.fields.product_id.label')
            },
            {
              field: UsbDevicesDescriptorListFieldEnum.vendor_id,
              displayName: t('fields.usb_config.fields.descriptors.fields.vendor_id.label')
            },
            {
              field: UsbDevicesDescriptorListFieldEnum.manufacture,
              displayName: t('fields.usb_config.fields.descriptors.fields.manufacture.label')
            },
            {
              field: UsbDevicesDescriptorListFieldEnum.product_description,
              displayName: t('fields.usb_config.fields.descriptors.fields.product_description.label')
            },
            {
              field: UsbDevicesDescriptorListFieldEnum.device_revision,
              displayName: t('fields.usb_config.fields.descriptors.fields.device_revision.label')
            },
            {
              field: UsbDevicesDescriptorListFieldEnum.usb_version,
              displayName: t('fields.usb_config.fields.descriptors.fields.usb_version.label'),
              cellFormatter: (rowData, value) => {
                return (
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    {value}
                    {!readOnly && !disableEdit && (
                      <div>
                        <GvtIconButton onClick={() => { edit(rowData) }}>
                          <Icon name={IconsEnum.FormEdit} />
                        </GvtIconButton>
                        <GvtIconButton onClick={() => { remove(rowData) }}>
                          <Icon name={IconsEnum.FormTrash} />
                        </GvtIconButton>
                      </div>
                    )}
                  </div>
                )
              }
            }
          ]} rows={descriptors} rowsRestriction={descriptors.length + 1} />
        </div>
      </StyledSimpleTable>
      )}
      {descriptors.length <= 0 && (<StyledSimpleTable><div className={error ? "has-error-self" : ""}>
        <GvtButton color='secondary' onClick={() => openNewDescriptor()} disabled={openNew}>{t('fields.usb_config.fields.descriptors.new')}</GvtButton>
      </div></StyledSimpleTable>)}
      <GvtModalDialog
        open={openNew}
        onClose={() => { onAddNew(false); setOpenNew(false) }}
        onBackdropClick={() => { onAddNew(false); setOpenNew(false) }}
        title={t('fields.usb_config.fields.descriptors.new')}
        buttons={[
          {
            onClick: () => { onAddNew(false); setOpenNew(false) },
            children: t('cancel', { ns: "common" })
          },
          {
            onClick: () => {
              if (validateAll()) {
                addNew();
              }
            },
            color: 'primary',
            children: t('add', { ns: "common" })
          }
        ]}
      >
        <StyledColumn>
          <DecoratedFormControl required={true} label={t('fields.usb_config.fields.descriptors.fields.product_id.label')} description={t('fields.usb_config.fields.descriptors.fields.product_id.description')} error={productIdError != ''} info={getMessage(UsbDevicesDescriptorListFieldEnum.product_id, productIdError)}>
            <GvtInput onBlur={(e) => { setProductId(e.target.value.padStart(4, "0").toUpperCase()) }} onChange={changeProductId} value={productId} inputProps={{ maxLength: 4 }} startAdornment={<>0x</>} />
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.usb_config.fields.descriptors.fields.vendor_id.label')} description={t('fields.usb_config.fields.descriptors.fields.vendor_id.description')} error={vendorIdError != ''} info={getMessage(UsbDevicesDescriptorListFieldEnum.vendor_id, vendorIdError)}>
            <GvtInput onBlur={(e) => { setVendorId(e.target.value.padStart(4, "0").toUpperCase()) }} onChange={changeVendorId} value={vendorId} inputProps={{ maxLength: 4 }} startAdornment={<>0x</>} />
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.usb_config.fields.descriptors.fields.manufacture.label')} description={t('fields.usb_config.fields.descriptors.fields.manufacture.description')} error={manufactureError != ''} info={getMessage(UsbDevicesDescriptorListFieldEnum.manufacture, manufactureError)}>
            <GvtInput onChange={changeManufacture} value={manufacture} />
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.usb_config.fields.descriptors.fields.product_description.label')} description={t('fields.usb_config.fields.descriptors.fields.product_description.description')} error={productError != ''} info={getMessage(UsbDevicesDescriptorListFieldEnum.product_description, productError)}>
            <GvtInput onChange={changeProduct} value={product} />
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.usb_config.fields.descriptors.fields.device_revision.label')} description={t('fields.usb_config.fields.descriptors.fields.device_revision.description')} error={bcdDeviceError != ''} info={getMessage(UsbDevicesDescriptorListFieldEnum.device_revision, bcdDeviceError)}>
            <GvtInput onChange={changeBcdDevice} value={bcdDevice} inputComponent={BcdCodedVersionMask} />
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.usb_config.fields.descriptors.fields.usb_version.label')} description={t('fields.usb_config.fields.descriptors.fields.usb_version.description')} error={versionError != ''} info={getMessage(UsbDevicesDescriptorListFieldEnum.usb_version, versionError)}>
            <GvtInput onChange={changeVersion} value={version} inputComponent={BcdCodedVersionMask} />
          </DecoratedFormControl>
        </StyledColumn>
      </GvtModalDialog>
    </>
  )
})

export default DescriptorTable;