import React, { useEffect, useMemo, useState } from 'react';


import { GvtMenuItem, GvtSelect, px2rem, TypographyEnum } from '@gravity/ui-components';
import { ListSubheader, TextField } from '@mui/material';
import { Icon, IconsEnum } from '@gravity/icons';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const StyledSearch = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;

  > .MuiTextField-root {
    width: 90% !important;
  }
`;

const StyledNoMatch = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;

  > .illustration {
    width: ${px2rem(100)};
    color: var(--text-weak);
    height: auto;
    fill: white;
  }

  > *:not(:last-child) {
    margin-bottom: 5px;
  }

  .extra {
    width: 100% !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;


const SearchSelect = ({
  onChange = () => { },
  value = null,
  displayValue = () => { },
  onSearch = () => { },
  options = [],
  onRender = null,
  notFoundExtra = null,
  optionKey = null,
  ...rest
}) => {
  const { t } = useTranslation(['common']);
  const [searchText, setSearchText] = useState("");
  const [displayedOptions, setDisplayedOptions] = useState(options);

  useEffect(() => {
    setDisplayedOptions(options.filter((option) => onSearch(option, searchText)))
  },
    [searchText, options]
  );

  return (
    <GvtSelect onChange={onChange} value={value} MenuProps={{ autoFocus: false }} onClose={() => setSearchText("")} renderValue={() => onRender ? onRender(value) : value} {...rest}>
      <StyledSearch>
        <TextField
          size="small"
          // Autofocus on textfield
          autoFocus
          placeholder={t('type_to_search')}
          InputProps={{
            startAdornment: (
              <Icon name={IconsEnum.CoreSearch} />
            )
          }}
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              // Prevents autoselecting item while typing (default Select behaviour)
              e.stopPropagation();
            }
          }}
        />
      </StyledSearch>
      {displayedOptions.map((opt, index) => (
        <GvtMenuItem value={optionKey ? opt[optionKey] : opt} key={index}>{displayValue(opt)}</GvtMenuItem>
      ))}
      {displayedOptions.length == 0 && (
        <StyledNoMatch>
          <Icon name={IconsEnum.IllustrationsEmpty} className='illustration' />
          <div className={TypographyEnum.TextNormalLarge}> {searchText ? t('not_found') : t('no_data_to_display')} </div>
          {searchText && notFoundExtra && (<div className="extra">{notFoundExtra}</div>)}
        </StyledNoMatch>
      )}
    </GvtSelect>
  )

}

export default SearchSelect;