import { IconsEnum } from '@gravity/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAppSecurityDetails } from '../../utils/apis';
import { GvtCardHeader, GvtModalDialog, SimpleTable } from '@gravity/ui-components';

const SecurityDetailsModal = (props) => {
    const { t } = useTranslation(['apps', 'common']);
    const [rowsData, setRowsData] = useState([]);
    const [multipleDevices, setMultipleDevices] = useState(false);
    const [deviceType, setDeviceType] = useState("");
    
    const deviceTypeEnum = {
        AP_Image: "AP_Image",
        DC_Image: "DC_Image"
    }

    useEffect(() => {
        if(!props.open) {
            return;
        }
        getAppSecurityDetails(props?.appId, props?.appVersion).then(response => {
            if(Object.keys(response.data).length > 1) {
                setMultipleDevices(true)
                const resp = response.data;
                let key1 = deviceTypeEnum.AP_Image;
                let key2 = deviceTypeEnum.DC_Image;
                const data = [
                    createRowsWithTwoCounts(t("app_security_details.critical", { ns: 'apps' }), resp[key1].critical, resp[key2].critical),
                    createRowsWithTwoCounts(t("app_security_details.high", { ns: 'apps' }), resp[key1].high, resp[key2].high),
                    createRowsWithTwoCounts(t("app_security_details.medium", { ns: 'apps' }), resp[key1].medium, resp[key2].medium),
                    createRowsWithTwoCounts(t("app_security_details.low", { ns: 'apps' }), resp[key1].low, resp[key2].low),
                    createRowsWithTwoCounts(t("app_security_details.unknown", { ns: 'apps' }), resp[key1].unknown, resp[key2].unknown)
                ];
                setRowsData(data);
            } else if(Object.keys(response.data).length === 1) {
                const resp = response.data;
                let key = Object.keys(resp)[0];
                setDeviceType(key);
                console.log(resp[key]);
                const data = [
                    createRows(t("app_security_details.critical", { ns: 'apps' }), resp[key].critical),
                    createRows(t("app_security_details.high", { ns: 'apps' }), resp[key].high),
                    createRows(t("app_security_details.medium", { ns: 'apps' }), resp[key].medium),
                    createRows(t("app_security_details.low", { ns: 'apps' }), resp[key].low),
                    createRows(t("app_security_details.unknown", { ns: 'apps' }), resp[key].unknown)
                ];
                setRowsData(data);
            }
        }).catch(error => {
            console.error(error);
        })
    }, [props?.appId, props?.appVersion, props?.open, multipleDevices, deviceType]);

    const onClose = (e, reason) => {
        setRowsData([]);
        if(reason !== 'backdropClick') {
            props.onClose(false);
        }
    }
    
    const createRows = (vulnerability, count) => {
        return { vulnerability, count };
    }

    const createRowsWithTwoCounts = (vulnerability, count1, count2) => {
        return { vulnerability, count1, count2 };
    }

    const getVulnerabilityIcon = (input) => {
        switch(input) {
            case 'Critical':
                return IconsEnum.SeveritySmallCritical
            case 'High':
                return IconsEnum.SeveritySmallMinor
            case 'Medium':
                return IconsEnum.SeveritySmallMajor
            case 'Low':
                return IconsEnum.HealthSmallGood
            case 'Unknown':
                return IconsEnum.SeveritySmallUnknown
        }
        return '';
    }

    return (
        <GvtModalDialog
            open={props.open}
            onClose={(e) => onClose(e)}
        >
            <GvtCardHeader title={t("app_security_details.title", { ns: 'apps' })} style={{marginTop: "-7%", marginBottom: "5%"}} />
            {multipleDevices && (
                <SimpleTable 
                    columns={
                        [
                            {
                                field: 'vulnerability',
                                displayName: t("app_security_details.vulnerability", { ns: 'apps' })
                            },
                            {
                                field: 'count1',
                                displayName: t("app_security_details.ap", { ns: 'apps' }),
                                icon: (rowData) => {
                                    return getVulnerabilityIcon(rowData?.vulnerability);
                                }
                            },
                            {
                                field: 'count2',
                                displayName: t("app_security_details.dc", { ns: 'apps' }),
                                icon: (rowData) => {
                                    return getVulnerabilityIcon(rowData?.vulnerability);
                                }
                            }
                        ]
                    }
                    rows={rowsData}
                />
            )}
            {!multipleDevices && (
                <SimpleTable
                    columns={
                        [
                            {
                                field: 'vulnerability',
                                displayName: t("app_security_details.vulnerability", { ns: 'apps' })
                            },
                            {
                                field: 'count',
                                displayName: deviceType === deviceTypeEnum.AP_Image ? t("app_security_details.ap", { ns: 'apps' }) : t("app_security_details.dc", { ns: 'apps' }),
                                icon: (rowData) => {
                                    return getVulnerabilityIcon(rowData?.vulnerability);
                                }
                            }
                        ]
                    }
                    rows={rowsData}
              />
            )}
        </GvtModalDialog>
    )
}

export default SecurityDetailsModal;