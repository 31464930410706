import { useEffect, useRef, useState } from "react";
import { getAppAttributes, changeAppAttributes, getApps } from "../../utils/apis";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { StyledPage, StyledToggle } from '../../components/styled-components';
import { Loading, GvtCard, GvtCardHeader, CaptionValue, PageLayout, ContextBar, GvtDrawer, px2rem, GvtButtonColorsEnum, DecoratedFormControl, Toggle, TypographyEnum, GvtButton, TableView, ViewModeEnum, FieldTypesEnum } from "@gravity/ui-components";
import { Icon, IconsEnum } from "@gravity/icons";
import { useParams } from "react-router-dom";
import AppVersionComparisonModal from "./app-version-comparison-modal";
import { AppStatusEnum } from "../../components/enums";
import AppsActionModal from "./apps.action.modal";
import SecurityDetailsModal from "./security.details.modal";

const StyledTableView = styled.div`
    position: relative;
    height: calc(100% - 5px);
    .aruba-grid-container {
        height: calc(100% - 22px) !important;
    }
`;

export const AppAttributesEnum = {
    true: "True",
    false: "False"
}

export const StyledColumnLayout = styled.div`
    margin-bottom:1rem;
    width: 40%;
`;

export const StyledBody = styled.div`
    font-size:1rem;
    position:relative;
    margin-top:-.75rem;
`;

const StyledLoader = styled.div`
  position: absolute;
  z-index: 999999;
  width: 100%;
  height:100%;
  opacity: .75;
`;

const Sidebar = styled.div`
    position: absolute; 
    height: 100%; 
    min-width: ${px2rem(510)};
    right:0; 
    top:0;

    & .MuiDialog-container {
        & .MuiPaper-root { 
            border-radius: 0.666667rem 0px 0px 0.666667rem;
            transition: transform 350ms cubic-bezier(0, 0, 0.2, 1) 0ms; 
            width: ${px2rem(490)};
            padding: 2rem 1.83333rem;
            box-shadow: 0 0.3333333333333333rem 0.6666666666666666rem var(--elevation-medium);
            border-left: 1px solid rgba(0, 0, 0, 0.12);
        }
      }
`;

const AppDetailsPage = () => {
    const { t } = useTranslation(['apps', 'common']);
    const grid = useRef(null);
    const [loading, setLoading] = useState(false);
    const [appAttributesData, setAppAttributesData] = useState(null);
    const [appDetailsData, setAppDetailsData] = useState(null);
    const { id } = useParams();
    const drawerContainerRef = useRef();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isRecommended, setIsRecommended] = useState(false);
    const [isInternal, setIsInternal] = useState(false);
    const [advanced, setAdvanced] = useState(false);
    const [isDefaultApp, setIsDefaultApp] = useState(false);

    const [openCompareVersions, setOpenCompareVersions] = useState(false);
    const [appVersions, setAppVersions] = useState([]);
    const [versionOne, setVersionOne] = useState(0);
    const [versionTwo, setVersionTwo] = useState(0);

    const [modalOpen, setModalOpen] = useState(false);
    const [appId, setAppId] = useState(null);
    const [appVersion, setAppVersion] = useState(null);
    const dialogRef = useRef();

    const fetchGridData = (paginationState, searchValue, quickFilters, sorting, filters) => {
        let parsedSort = {}
        if ('fields' in sorting && sorting['fields'].length > 0) {
            for (let i = 0; i < sorting['fields'].length; i++) {
                parsedSort[sorting['fields'][i]['field']] = sorting['fields'][i]['order'].toLowerCase() === 'asc' ? 1 : -1;
            }
        }
        let query = {};
        query['app_id'] = id
        return getApps({
            pageNumber: (paginationState.offset / paginationState.limit) + 1, 
            pageSize: paginationState.limit, 
            sort: parsedSort, 
            query: query
        }).then((response) => {
            return {
                rows: response.data.content,
                total: response.data.total_elements
            }
        }, (error) => {
            console.error(error);
            return {
                rows: [],
                total: 0,
            }
        })
    }

    useEffect(() => {
        setLoading(true);
        getAppAttributes(id).then(response => {
            console.log(response);
            setAppAttributesData(response.data.attributes);
            setAppDetailsData(response.data);
            setIsRecommended(response.data.attributes.is_recommended);
            setIsInternal(response.data.attributes.isinternal);
            setAdvanced(response.data.attributes.advanced);
            setIsDefaultApp(response.data.attributes.is_default_app);
            setLoading(false);
        }).catch(error => {
            console.error(error);
            setLoading(false);
        });
    }, [id]);

    const updateApp = (action, data) => {
        if(action == 'delete') {
            grid.current.api.purgeInfiniteCache();
        } else {
            grid.current.api.forEachNode((rowNode, index) => {
                if (rowNode.data['app_id'] == data['app_id'] && rowNode.data['version'] == data['version']) {
                    rowNode.setData(data);
                }
            })
        }
    }

    const openCompareVersionsModal = () => {
        let query = {};
        query['app_id'] = id
        getApps({ query: query }).then((response) => {
            const data = response.data.content;
            let versions = [];
            for(const vs in data) {
                versions.push(data[vs].version);
            }
            if(versions.length > 1) {
                setAppVersions(versions.sort((a, b) => a - b));
                setVersionOne(versions.sort((a, b) => a - b)[0]);
                setVersionTwo(versions.sort((a, b) => a - b)[1]);
            } else {
                setAppVersions(versions.sort((a, b) => a - b));
                setVersionOne(versions.sort((a, b) => a - b)[0]);
                setVersionTwo(versions.sort((a, b) => a - b)[0]);
            }
            setOpenCompareVersions(true);
        }, (error) => {
            console.error(error);
        })
    }

    const onClick = () => {
        setDrawerOpen(!drawerOpen);
        console.log(drawerOpen);
    }

    const onSubmit = () => {
        changeAppAttributes(appDetailsData?.app_id, {
            "developer_id": appDetailsData?.developer_id,
            "attributes": {
                "advanced": advanced,
                "isinternal": isInternal,
                "is_recommended": isRecommended,
                "is_default_app": isDefaultApp
            }
        }, error => {
            console.log(error);
            setLoading(false);
        });
        setLoading(true);
        setTimeout(() => {
            window.location.reload();
        }, 200);
    }

    const drawerButtons = [
        {   
            onClick: () => setDrawerOpen(false),
            children: t('cancel', { ns: "common" })
        },
        {
            onClick: () => onSubmit(),
            children: t('save', { ns: "common" }),
            color: GvtButtonColorsEnum.Primary
        }
    ]

    const changeIsRecommended = (e) => {
        setIsRecommended(e.target.checked);
    } 

    const changeIsInternal = (e) => {
        setIsInternal(e.target.checked);
    }

    const changeAdvanced = (e) => {
        setAdvanced(e.target.checked);
    }

    const changeIsDefaultApp = (e) => {
        setIsDefaultApp(e.target.checked);
    }

    return(
        <>  
            {loading && (<StyledLoader><Loading /></StyledLoader>)}
            <PageLayout>
                <ContextBar 
                    title={appDetailsData?.name}
                    description={appDetailsData?.status}
                />
                <StyledPage style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                    <GvtCard style={{height: "100%", width: "25%"}} CoreEdit="true" edit="true">
                        <div style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                            <GvtCardHeader title={t('app_attributes.title', { ns: 'apps' })} />
                            <Icon name={IconsEnum.FormEdit} onClick={onClick} style={{ cursor: 'pointer' }} />
                        </div>
                        <br />
                        <CaptionValue 
                            caption={t('app_attributes.is_recommended')}
                            primary={AppAttributesEnum[appAttributesData?.is_recommended]}
                        />
                        <br />
                        <CaptionValue 
                            caption={t('app_attributes.is_internal')}
                            primary={AppAttributesEnum[appAttributesData?.isinternal]}
                        />
                        <br />
                        <CaptionValue 
                            caption={t('app_attributes.advanced')}
                            primary={AppAttributesEnum[appAttributesData?.advanced]}
                        />
                        <br />
                        <CaptionValue 
                            caption={t('app_attributes.is_default_app')}
                            primary={AppAttributesEnum[appAttributesData?.is_default_app]}
                        />
                    </GvtCard>
                    <GvtCard style={{height: "100%", width: "72%"}} CoreEdit="true" edit="true">
                        <h1 style={{marginTop: "1%", marginLeft: "1%", marginBottom: "1%"}} className={TypographyEnum.H1Small}>{t('app_versions.title', { ns: 'apps' })}</h1>
                        <div style={{marginTop: "2%", display: "flex", flexDirection: "row", justifyContent: "flex-end", zIndex: 999}}>
                            <GvtButton color="secondary" onClick={() => {openCompareVersionsModal()}}>
                                {t('app_versions.compare', { ns: 'apps' })}
                                <Icon name={IconsEnum.ControlsCompare} style={{ marginLeft: '5%' }} />
                            </GvtButton>
                        </div>
                        <StyledTableView style={{ zIndex: 999 }}>
                            <TableView 
                                gridConfig={{
                                    viewMode: ViewModeEnum.CompactView,
                                    fieldsCompactView: [
                                        {
                                            field: 'version',
                                            displayName: t('version', { ns: 'common' }),
                                            sortable: true,
                                            fieldType: FieldTypesEnum.Integer
                                        },
                                        {
                                            field: 'status',
                                            displayName: t('status', { ns: 'common' }),
                                            valueFormatter: (params) => {
                                                return params && 'status' in params && params.status ? (t('app_status.' + params?.status, { ns: 'common' })) : ''
                                            },
                                            multiValueField: {
                                                valueFormatter: (rowData) => {
                                                    return rowData?.status_reason;
                                                }
                                            },
                                            sortable: true,
                                            icon: (rowData) => {
                                                const fieldValue = rowData?.status;
                                                switch (fieldValue) {
                                                case AppStatusEnum.SUSPENDED:
                                                    return IconsEnum.HealthSmallUnknown;
                                                case AppStatusEnum.APPROVED_APP:
                                                    return IconsEnum.HealthSmallGood;
                                                case AppStatusEnum.REJECTED_DRAFT:
                                                    return IconsEnum.HealthSmallPoor;
                                                default:
                                                    return IconsEnum.HealthSmallFair;
                                                }
                                            }
                                        },
                                        {
                                            field: 'modified_by',
                                            displayName: t('modified_by', { ns: 'common' }),
                                            sortable: true,
                                            fieldType: FieldTypesEnum.Text
                                        },
                                        {
                                            field: 'updated_at',
                                            displayName: t('updated_at', { ns: 'common' }),
                                            valueFormatter: (params) => {
                                                return params && 'updated_at' in params && params.updated_at ? new Date(params?.updated_at).toLocaleString() : '';
                                            },
                                            sortable: true,
                                            fieldType: FieldTypesEnum.Text
                                        }
                                    ],
                                    fieldsComfortView: [],
                                    fetchGridData: fetchGridData,
                                    events: {
                                        onGridReady: (e) => {
                                            grid.current = e;
                                        }
                                    },
                                    rowActions: {
                                        delete: true,
                                        onAction: (action, rowData) => {
                                            if(action.id === "security_report") {
                                                setAppId(rowData.app_id);
                                                setAppVersion(rowData.version);
                                                setModalOpen(true);
                                            } else {
                                                dialogRef.current.doSetAppAction(action.id, rowData);
                                            }
                                        },
                                        menuItems: [
                                            {
                                                label: t("approve", { ns: 'common' }),
                                                id: 'approve'
                                            },
                                            {
                                                label: t("reject", { ns: 'common' }),
                                                id: 'reject'
                                            },
                                            {
                                                label: t("suspend", { ns: 'common' }),
                                                id: 'suspend'
                                            },
                                            {
                                                label: t("security_scan", { ns: 'common' }),
                                                id: 'security_report'
                                            }
                                        ]
                                    }
                                }}
                                columnCustomization={false}
                                search={false}
                            />
                        </StyledTableView>
                    </GvtCard>

                    <Sidebar ref={drawerContainerRef} />
                    <GvtDrawer 
                        container={() => drawerContainerRef.current}
                        isOpen={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        title={t('app_attributes.title', { ns: 'apps' })}
                        category={t('app_attributes.drawer.category', { ns: 'apps' })}
                        description={t('app_attributes.drawer.description', { ns: 'apps' })}
                        buttons={drawerButtons}
                    >
                        {loading && (<StyledLoader><Loading /></StyledLoader>)}
                        <StyledColumnLayout>
                            <DecoratedFormControl>
                                <StyledToggle>
                                    <Toggle label={t('app_attributes.is_recommended')} onChange={changeIsRecommended} value={isRecommended} defaultChecked={isRecommended} />
                                </StyledToggle>
                            </DecoratedFormControl>
                            <br />
                            <DecoratedFormControl>
                                <StyledToggle>
                                    <Toggle label={t('app_attributes.is_internal')} onChange={changeIsInternal} value={isInternal} defaultChecked={isInternal}  />
                                </StyledToggle>
                            </DecoratedFormControl>
                            <br />
                            <DecoratedFormControl>
                                <StyledToggle>
                                    <Toggle label={t('app_attributes.advanced')} onChange={changeAdvanced} value={advanced} defaultChecked={advanced}  />
                                </StyledToggle>
                            </DecoratedFormControl>
                            <br />
                            <DecoratedFormControl>
                                <StyledToggle>
                                    <Toggle label={t('app_attributes.is_default_app')} onChange={changeIsDefaultApp} value={isDefaultApp} defaultChecked={isDefaultApp} />
                                </StyledToggle>
                            </DecoratedFormControl>
                        </StyledColumnLayout>
                    </GvtDrawer>

                    <AppVersionComparisonModal 
                        openCompareVersions={openCompareVersions} 
                        setOpenCompareVersions={setOpenCompareVersions} 
                        appVersions={appVersions}
                        setAppVersions={setAppVersions}
                        versionOne={versionOne} 
                        setVersionOne={setVersionOne} 
                        versionTwo={versionTwo} 
                        setVersionTwo={setVersionTwo}
                    />

                    <AppsActionModal ref={dialogRef} updateApp={updateApp} />

                    <SecurityDetailsModal 
                        open={modalOpen}
                        setModalOpen={setModalOpen}
                        onClose={() => setModalOpen(false)}
                        appId={appId}
                        appVersion={appVersion}
                    />
                </StyledPage>
            </PageLayout>
        </>
    );
}

export { AppDetailsPage }