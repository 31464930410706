import { Icon, IconsEnum } from "@gravity/icons";
import { DecoratedFormControl, GvtButton, GvtIconButton, GvtMenuItem, GvtModalDialog, GvtSelect, SimpleTable } from "@gravity/ui-components";
import _ from "lodash";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { StyledColumn, StyledSimpleTable, StyledSimpleTableAdd } from "../styled-components";
import { translateDeviceClass } from "../utils";
import { AppDeviceClassPermissionsFieldEnum, CreationWizardEnum, PermissionsEnum } from "../enums";


export const PermissionsTable = ({
  permissions = [],
  error = false,
  deviceClasses = [],
  onChange = () => { },
  readOnly = false,
  permissionFor = CreationWizardEnum.ble,
  disableEdit = false
}) => {
  const { t } = useTranslation(['appCreation', "common"]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const permissionTypes = [
    { name: t('block', { ns: "common" }), id: PermissionsEnum.block },
    { name: t('read_only', { ns: "common" }), id: PermissionsEnum.read_only },
    { name: t('read_write', { ns: "common" }), id: PermissionsEnum.read_write }
  ]

  const [errors, setErrors] = useState({
    [AppDeviceClassPermissionsFieldEnum.device_class]: { error: false, type: null, props: {} },
    [AppDeviceClassPermissionsFieldEnum.ble_script_access]: { error: false, type: null, props: {} },
    [AppDeviceClassPermissionsFieldEnum.zigbee_access]: { error: false, type: null, props: {} },
    [AppDeviceClassPermissionsFieldEnum.serial_access]: { error: false, type: null, props: {} },
    [AppDeviceClassPermissionsFieldEnum.device_context_access]: { error: false, type: null, props: {} },
    [AppDeviceClassPermissionsFieldEnum.ble_gatt_operations]: { error: false, type: null, props: {} },
  })

  const [deviceClass, setDeviceClass] = useState(deviceClasses[0]?.id);
  const [bleScriptAccess, setBleScriptAccess] = useState(PermissionsEnum.block);
  const [zigbeeAccess, setZigbeeAccess] = useState(PermissionsEnum.block);
  const [serialAccess, setSerialAccess] = useState(PermissionsEnum.block);
  const [deviceContextAccess, setDeviceContextAccess] = useState(PermissionsEnum.block);
  const [bleGattOperations, setBleGattOperations] = useState(PermissionsEnum.block);
  const [editIndex, setEditIndex] = useState(-1);

  const changeError = (errorArray) => {
    let e = _.cloneDeep(errors);
    for (let i = 0; i < errorArray.length; i++) {
      e[errorArray[i].key] = {
        error: errorArray[i].hasError,
        type: errorArray[i].hasError ? errorArray[i].type : '',
        props: errorArray[i].hasError ? errorArray[i].props : {}
      }
    }
    setErrors(e);
  }

  const changeSingleError = (hasError, key, type, props = {}) => {
    changeError([{ hasError: hasError, key: key, type: type, props: props }])
  }

  const removeBlockedError = () => {
    let e = _.cloneDeep(errors);
    for (let key in e) {
      if (e[key].error && e[key].type == 'all_blocked') {
        e[key].error = false;
        e[key].type = null;
      }
    }
    setErrors(e);
  }

  const validateAll = () => {
    changeSingleError(!deviceClass, AppDeviceClassPermissionsFieldEnum.device_class, 'required');
    if (permissionFor == CreationWizardEnum.ble) {
      changeSingleError(bleScriptAccess == PermissionsEnum.block, AppDeviceClassPermissionsFieldEnum.ble_script_access, 'all_blocked');
      return !(!deviceClass || bleScriptAccess == PermissionsEnum.block)
    } else if (permissionFor == CreationWizardEnum.zigbee) {
      changeSingleError(zigbeeAccess == PermissionsEnum.block, AppDeviceClassPermissionsFieldEnum.zigbee_access, 'all_blocked');
      return !(!deviceClass || zigbeeAccess == PermissionsEnum.block)
    } else {
      let allBlocked = serialAccess == PermissionsEnum.block && deviceContextAccess == PermissionsEnum.block && bleGattOperations == PermissionsEnum.block;
      changeError([{ hasError: allBlocked, key: AppDeviceClassPermissionsFieldEnum.serial_access, type: 'all_blocked' },
      { hasError: allBlocked, key: AppDeviceClassPermissionsFieldEnum.device_context_access, type: 'all_blocked' },
      { hasError: allBlocked, key: AppDeviceClassPermissionsFieldEnum.ble_gatt_operations, type: 'all_blocked' }]);
      return !(!deviceClass || allBlocked)
    }
  }

  const removeDuplicatedClassNameError = () => {
    if (errors[AppDeviceClassPermissionsFieldEnum.device_class].error && errors[AppDeviceClassPermissionsFieldEnum.device_class].type == 'duplicated') {
      changeSingleError(false, AppDeviceClassPermissionsFieldEnum.device_class, 'duplicated');
    }
  }

  const changeDeviceClass = (e) => {
    setDeviceClass(e.target.value);
    changeSingleError(!e.target.value, AppDeviceClassPermissionsFieldEnum.device_class, 'required');
    removeDuplicatedClassNameError();
  }

  const changeBleScriptAccess = (e) => {
    setBleScriptAccess(e.target.value);
    changeSingleError(!e.target.value, AppDeviceClassPermissionsFieldEnum.ble_script_access, 'required');
    removeDuplicatedClassNameError();
  }

  const changeZigbeeAccess = (e) => {
    setZigbeeAccess(e.target.value);
    changeSingleError(!e.target.value, AppDeviceClassPermissionsFieldEnum.zigbee_access, 'required');
    removeDuplicatedClassNameError();
  }

  const changeSerialAccess = (e) => {
    setSerialAccess(e.target.value);
    changeSingleError(!e.target.value, AppDeviceClassPermissionsFieldEnum.serial_access, 'required');
    removeDuplicatedClassNameError();
    removeBlockedError();
  }

  const changeDeviceContextAccess = (e) => {
    setDeviceContextAccess(e.target.value);
    changeSingleError(!e.target.value, AppDeviceClassPermissionsFieldEnum.device_context_access, 'required');
    removeDuplicatedClassNameError();
    removeBlockedError();
  }

  const changeBleGattOperations = (e) => {
    setBleGattOperations(e.target.value);
    changeSingleError(!e.target.value, AppDeviceClassPermissionsFieldEnum.ble_gatt_operations, 'required');
    removeDuplicatedClassNameError();
    removeBlockedError();
  }

  const getDeviceClassMessage = () => {
    if (errors[AppDeviceClassPermissionsFieldEnum.device_class].error) {
      return t('fields.permissions.fields.device_class.error.' + errors[AppDeviceClassPermissionsFieldEnum.device_class].type)
    }
    return ''
  }

  const getPermissionMessage = (key) => {
    if (errors[key].error) {
      if ((permissionFor == CreationWizardEnum.ble || permissionFor == CreationWizardEnum.zigbee) && errors[key].type == 'all_blocked') {
        return t('fields.permissions.fields.error.single_blocked')
      }
      return t('fields.permissions.fields.error.' + errors[key].type)
    }
    if (permissionFor == CreationWizardEnum.ble || permissionFor == CreationWizardEnum.zigbee) {
      return t('fields.permissions.fields.single_help')
    }
    return t('fields.permissions.fields.help');
  }

  const openModal = () => {
    setDialogOpen(true);
    setDeviceClass(deviceClasses[0]?.id);
    setBleScriptAccess(PermissionsEnum.block);
    setZigbeeAccess(PermissionsEnum.block);
    setSerialAccess(PermissionsEnum.block);
    setDeviceContextAccess(PermissionsEnum.block);
    setBleGattOperations(PermissionsEnum.block);
    clearError();
    setEditIndex(-1)
  }

  const clearError = () => {
    let e = _.cloneDeep(errors);
    for (let key in e) {
      e[key].error = false;
      e[key].type = null;
    }
    setErrors(e);
  }

  const addPermission = (device_class, ble_script_access, zigbee_access, serial_access, device_context_access, ble_gatt_operations) => {
    let permissionsList = _.cloneDeep(permissions);
    let permission = null;
    let index = -1;
    for (let i = 0; i < permissionsList.length; i++) {
      if (permissionsList[i][AppDeviceClassPermissionsFieldEnum.device_class] == device_class) {
        index = i;
        permission = _.cloneDeep(permissionsList[i]);
        break;
      }
    }
    let newConfig = {
      [AppDeviceClassPermissionsFieldEnum.device_class]: device_class,
      [AppDeviceClassPermissionsFieldEnum.ble_script_access]: ble_script_access,
      [AppDeviceClassPermissionsFieldEnum.zigbee_access]: zigbee_access,
      [AppDeviceClassPermissionsFieldEnum.serial_access]: serial_access,
      [AppDeviceClassPermissionsFieldEnum.device_context_access]: device_context_access,
      [AppDeviceClassPermissionsFieldEnum.ble_gatt_operations]: ble_gatt_operations
    }
    let isDiff = false;
    if (index >= 0) {
      if (permission[AppDeviceClassPermissionsFieldEnum.ble_script_access] == PermissionsEnum.block && permission[AppDeviceClassPermissionsFieldEnum.ble_script_access] != ble_script_access) {
        isDiff = true;
        permissionsList[index][AppDeviceClassPermissionsFieldEnum.ble_script_access] = ble_script_access;
      }
      if (permission[AppDeviceClassPermissionsFieldEnum.zigbee_access] == PermissionsEnum.block && permission[AppDeviceClassPermissionsFieldEnum.zigbee_access] != zigbee_access) {
        isDiff = true;
        permissionsList[index][AppDeviceClassPermissionsFieldEnum.zigbee_access] = zigbee_access;
      }
      if (permission[AppDeviceClassPermissionsFieldEnum.serial_access] == PermissionsEnum.block && permission[AppDeviceClassPermissionsFieldEnum.serial_access] != serial_access) {
        isDiff = true;
        permissionsList[index][AppDeviceClassPermissionsFieldEnum.serial_access] = serial_access;
      }
      if (permission[AppDeviceClassPermissionsFieldEnum.device_context_access] == PermissionsEnum.block && permission[AppDeviceClassPermissionsFieldEnum.device_context_access] != device_context_access) {
        isDiff = true;
        permissionsList[index][AppDeviceClassPermissionsFieldEnum.device_context_access] = device_context_access;
      }
      if (permission[AppDeviceClassPermissionsFieldEnum.ble_gatt_operations] == PermissionsEnum.block && permission[AppDeviceClassPermissionsFieldEnum.ble_gatt_operations] != ble_gatt_operations) {
        isDiff = true;
        permissionsList[index][AppDeviceClassPermissionsFieldEnum.ble_gatt_operations] = ble_gatt_operations;
      }
    } else {
      isDiff = true;
      permissionsList.push(newConfig);
    }
    if (editIndex != -1) {
      changeSingleError(false, AppDeviceClassPermissionsFieldEnum.device_class, 'duplicated');
      permissionsList[editIndex] = newConfig;
      setDialogOpen(false);
      onChange(permissionsList);
    } else if (!isDiff) {
      changeSingleError(true, AppDeviceClassPermissionsFieldEnum.device_class, 'duplicated');
    } else {
      changeSingleError(false, AppDeviceClassPermissionsFieldEnum.device_class, 'duplicated');
      setDialogOpen(false);
      onChange(permissionsList);
    }
  }

  const removePermission = (value) => {
    let permissionsList = _.cloneDeep(permissions);
    for (let i = 0; i < permissionsList.length; i++) {
      let p = permissionsList[i];
      if (p[AppDeviceClassPermissionsFieldEnum.device_class] == value[AppDeviceClassPermissionsFieldEnum.device_class]) {
        let countNotBlocked = 0;
        for (let key in p) {
          if (key != AppDeviceClassPermissionsFieldEnum.device_class) {
            countNotBlocked = p[key] != PermissionsEnum.block ? countNotBlocked + 1 : countNotBlocked;
          }
        }
        if (permissionFor == CreationWizardEnum.ble) {
          if (countNotBlocked == 1) {
            permissionsList.splice(i, 1);
          } else {
            p[AppDeviceClassPermissionsFieldEnum.ble_script_access] = PermissionsEnum.block;
          }
        } else if (permissionFor == CreationWizardEnum.zigbee) {
          if (countNotBlocked == 1) {
            permissionsList.splice(i, 1);
          } else {
            p[AppDeviceClassPermissionsFieldEnum.zigbee_access] = PermissionsEnum.block;
          }
        } else {
          if (p[AppDeviceClassPermissionsFieldEnum.ble_script_access] != PermissionsEnum.block ||
            p[AppDeviceClassPermissionsFieldEnum.zigbee_access] != PermissionsEnum.block) {
            p[AppDeviceClassPermissionsFieldEnum.serial_access] = PermissionsEnum.block;
            p[AppDeviceClassPermissionsFieldEnum.device_context_access] = PermissionsEnum.block;
            p[AppDeviceClassPermissionsFieldEnum.ble_gatt_operations] = PermissionsEnum.block;
          } else {
            permissionsList.splice(i, 1);
          }
        }
        break;
      }
    }
    onChange(permissionsList);
  }

  const editPermission = (value) => {
    for (let i = 0; i < permissions.length; i++) {
      if (_.isEqual(value, permissions[i])) {
        setEditIndex(i);
        break;
      }
    }
    setDialogOpen(true);
    setDeviceClass(value[AppDeviceClassPermissionsFieldEnum.device_class]);
    setBleScriptAccess(value[AppDeviceClassPermissionsFieldEnum.ble_script_access]);
    setZigbeeAccess(value[AppDeviceClassPermissionsFieldEnum.zigbee_access]);
    setSerialAccess(value[AppDeviceClassPermissionsFieldEnum.serial_access]);
    setDeviceContextAccess(value[AppDeviceClassPermissionsFieldEnum.device_context_access]);
    setBleGattOperations(value[AppDeviceClassPermissionsFieldEnum.ble_gatt_operations]);
    clearError();
  }

  const getCols = () => {
    let cols = [
      {
        field: AppDeviceClassPermissionsFieldEnum.device_class,
        displayName: t('fields.permissions.fields.device_class.label'),
        cellFormatter: (rowData, value) => {
          return (
            <>
              {translateDeviceClass(deviceClasses, value)}
            </>
          )
        }
      }
    ]
    const cellFormatterDel = (rowData, value) => {
      return (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {t(value.toLowerCase(), { ns: 'common' })}
          {!readOnly && !disableEdit && (
            <div>
              <GvtIconButton onClick={() => { editPermission(rowData) }}>
                <Icon name={IconsEnum.FormEdit} />
              </GvtIconButton>
              <GvtIconButton onClick={() => { removePermission(rowData) }}>
                <Icon name={IconsEnum.FormTrash} />
              </GvtIconButton>
            </div>
          )}
        </div>
      )
    }
    const cellFormatterPermission = (rowData, value) => {
      return (
        <>
          {t(value.toLowerCase(), { ns: 'common' })}
        </>
      )
    }
    if (permissionFor == CreationWizardEnum.ble) {
      cols.push({
        field: AppDeviceClassPermissionsFieldEnum.ble_script_access,
        displayName: t('fields.permissions.fields.ble_script_access.label'),
        cellFormatter: cellFormatterDel
      })
    } else if (permissionFor == CreationWizardEnum.zigbee) {
      cols.push({
        field: AppDeviceClassPermissionsFieldEnum.zigbee_access,
        displayName: t('fields.permissions.fields.zigbee_access.label'),
        cellFormatter: cellFormatterDel
      })
    } else {
      cols.push({
        field: AppDeviceClassPermissionsFieldEnum.serial_access,
        displayName: t('fields.permissions.fields.serial_access.label'),
        cellFormatter: cellFormatterPermission
      })
      cols.push({
        field: AppDeviceClassPermissionsFieldEnum.device_context_access,
        displayName: t('fields.permissions.fields.device_context_access.label'),
        cellFormatter: cellFormatterPermission
      })
      cols.push({
        field: AppDeviceClassPermissionsFieldEnum.ble_gatt_operations,
        displayName: t('fields.permissions.fields.ble_gatt_operations.label'),
        cellFormatter: cellFormatterDel
      })
    }
    return cols;
  }

  const getRows = () => {
    return permissionFor == CreationWizardEnum.ble ?
      permissions.filter(val => val[AppDeviceClassPermissionsFieldEnum.ble_script_access] != PermissionsEnum.block) :
      (permissionFor == CreationWizardEnum.zigbee ?
        permissions.filter(val => val[AppDeviceClassPermissionsFieldEnum.zigbee_access] != PermissionsEnum.block) :
        permissions.filter(val => val[AppDeviceClassPermissionsFieldEnum.serial_access] != PermissionsEnum.block ||
          val[AppDeviceClassPermissionsFieldEnum.device_context_access] != PermissionsEnum.block ||
          val[AppDeviceClassPermissionsFieldEnum.ble_gatt_operations] != PermissionsEnum.block)
      )
  }

  return (
    <>
      <StyledSimpleTable>
        {!readOnly && !disableEdit && getRows().length > 0 && (<StyledSimpleTableAdd>
          <GvtIconButton onClick={() => { openModal() }} disabled={dialogOpen}>
            <Icon name={IconsEnum.FormAdd} />
          </GvtIconButton>
        </StyledSimpleTableAdd>)}
        <div className={error ? (getRows().length <= 0 && !readOnly ? "has-error-self" : "has-error") : ""}>
          {getRows().length > 0 && (<SimpleTable columns={getCols()}
            rows={getRows()} rowsRestriction={getRows().length + 1}
          />)}
          {getRows().length <= 0 && !readOnly && (<GvtButton color='secondary' onClick={() => openModal()} disabled={dialogOpen}>{t('fields.permissions.new.title')}</GvtButton>)}
          {getRows().length <= 0 && readOnly && (<div>{t('fields.permissions.no_data')}</div>)}
        </div>
      </StyledSimpleTable>
      <GvtModalDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onBackdropClick={() => setDialogOpen(false)}
        title={t('fields.permissions.new.title')}
        description={permissionFor == CreationWizardEnum.ble || permissionFor == CreationWizardEnum.zigbee ? t('fields.permissions.new.single_description') : t('fields.permissions.new.description')}
        buttons={[
          {
            onClick: () => setDialogOpen(false),
            children: t('cancel', { ns: "common" })
          },
          {
            onClick: () => {
              if (validateAll()) {
                addPermission(deviceClass, bleScriptAccess, zigbeeAccess, serialAccess, deviceContextAccess, bleGattOperations);
              }
            },
            color: 'primary',
            children: t('save', { ns: "common" })
          }
        ]}
      >
        <StyledColumn>
          <DecoratedFormControl required={true} label={t('fields.permissions.fields.device_class.label')} description={t('fields.permissions.fields.device_class.description')} error={errors[AppDeviceClassPermissionsFieldEnum.device_class].error} info={getDeviceClassMessage()}>
            <GvtSelect onChange={changeDeviceClass} value={deviceClass}>
              {deviceClasses.map((device_class, index) => (
                <GvtMenuItem key={'device_class' + index} value={device_class.id}>{device_class.name}</GvtMenuItem>
              ))}
            </GvtSelect>
          </DecoratedFormControl>
          {permissionFor == CreationWizardEnum.ble && (<DecoratedFormControl required={true} label={t('fields.permissions.fields.ble_script_access.label')} description={t('fields.permissions.fields.ble_script_access.description')} error={errors[AppDeviceClassPermissionsFieldEnum.ble_script_access].error} info={getPermissionMessage(AppDeviceClassPermissionsFieldEnum.ble_script_access)}>
            <GvtSelect onChange={changeBleScriptAccess} value={bleScriptAccess}>
              {permissionTypes.map((sub, index) => (
                <GvtMenuItem key={'sub1' + index} value={sub.id}>{sub.name}</GvtMenuItem>
              ))}
            </GvtSelect>
          </DecoratedFormControl>)}
          {permissionFor == CreationWizardEnum.zigbee && (<DecoratedFormControl required={true} label={t('fields.permissions.fields.zigbee_access.label')} description={t('fields.permissions.fields.zigbee_access.description')} error={errors[AppDeviceClassPermissionsFieldEnum.zigbee_access].error} info={getPermissionMessage(AppDeviceClassPermissionsFieldEnum.zigbee_access)}>
            <GvtSelect onChange={changeZigbeeAccess} value={zigbeeAccess}>
              {permissionTypes.map((sub, index) => (
                <GvtMenuItem key={'sub2' + index} value={sub.id}>{sub.name}</GvtMenuItem>
              ))}
            </GvtSelect>
          </DecoratedFormControl>)}
          {permissionFor == CreationWizardEnum.edge_compute && (
            <>
              <DecoratedFormControl required={true} label={t('fields.permissions.fields.serial_access.label')} description={t('fields.permissions.fields.serial_access.description')} error={errors[AppDeviceClassPermissionsFieldEnum.serial_access].error} info={getPermissionMessage(AppDeviceClassPermissionsFieldEnum.serial_access)}>
                <GvtSelect onChange={changeSerialAccess} value={serialAccess}>
                  {permissionTypes.map((sub, index) => (
                    <GvtMenuItem key={'sub3' + index} value={sub.id}>{sub.name}</GvtMenuItem>
                  ))}
                </GvtSelect>
              </DecoratedFormControl>
              <DecoratedFormControl required={true} label={t('fields.permissions.fields.device_context_access.label')} description={t('fields.permissions.fields.device_context_access.description')} error={errors[AppDeviceClassPermissionsFieldEnum.device_context_access].error} info={getPermissionMessage(AppDeviceClassPermissionsFieldEnum.device_context_access)}>
                <GvtSelect onChange={changeDeviceContextAccess} value={deviceContextAccess}>
                  {permissionTypes.map((sub, index) => (
                    <GvtMenuItem key={'sub4' + index} value={sub.id}>{sub.name}</GvtMenuItem>
                  ))}
                </GvtSelect>
              </DecoratedFormControl>
              <DecoratedFormControl required={true} label={t('fields.permissions.fields.ble_gatt_operations.label')} description={t('fields.permissions.fields.ble_gatt_operations.description')} error={errors[AppDeviceClassPermissionsFieldEnum.ble_gatt_operations].error} info={getPermissionMessage(AppDeviceClassPermissionsFieldEnum.ble_gatt_operations)}>
                <GvtSelect onChange={changeBleGattOperations} value={bleGattOperations}>
                  {permissionTypes.map((sub, index) => (
                    <GvtMenuItem key={'sub5' + index} value={sub.id}>{sub.name}</GvtMenuItem>
                  ))}
                </GvtSelect>
              </DecoratedFormControl>
            </>
          )}
        </StyledColumn>
      </GvtModalDialog>
    </>
  )
}

export default PermissionsTable;