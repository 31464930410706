const userApi = "/v1/adp/user";
const organizationApi = "/v1/adp/organization";
const loginApi = "/v1/adp/login";
const appsApi = "/v1/adp/apps";
const organizationsPendingApi = "/v1/adp/organizations/pending";
const requestsPendingApi = "/v1/adp/requests/pending";
const appsPendingApi = "/v1/adp/apps/pending";
const appsRejectApi = "/v1/adp/apps/reject";
const appsApproveApi = "/v1/adp/apps/approve";
const appsSuspendApi = "/v1/adp/apps/suspend";
const appsReviewApi = "/v1/adp/apps/review";
const appsVersionApi = "/v1/adp/apps/version";
const appVersionsComparisonApi = "/v1/adp/apps/:id/version/:version1/compare/:version2";
const appsSecurityDetailsApi = "/v1/adp/apps/:id/:version/securitydetails";
const requestsApi = "/v1/adp/requests";
const commentsApi = "/v1/adp/requests/:id/comments";
const createRequestsApi = "/v1/adp/requests/create";
const requestStatusApi = "/v1/adp/requests/:id/:status";
const requestDeviceClassStatusApi = "/v1/adp/requests/deviceClass/:id/:status";
const requestDeviceClassDetailsApi = "/v1/adp/requests/deviceClass/:id/deviceRequestDetails";
const organizationsApi = "/v1/adp/organizations";
const organizationsExpandApi = "/v1/adp/organizations/:id";
const organizationsStatusApi = "/v1/adp/organizations/:id/:status";
const appDetailsApi = "/v1/adp/apps/:id/details";
const appAttributesApi = "/v1/adp/apps/:id/attributes";


module.exports = {
  userApi,
  organizationApi,
  loginApi,
  appsApi,
  organizationsPendingApi,
  requestsPendingApi,
  appsPendingApi,
  appsRejectApi,
  appsApproveApi,
  appsSuspendApi,
  appsReviewApi,
  appsVersionApi,
  appVersionsComparisonApi,
  appsSecurityDetailsApi,
  requestsApi,
  commentsApi,
  createRequestsApi,
  requestStatusApi,
  requestDeviceClassStatusApi,
  requestDeviceClassDetailsApi,
  organizationsApi,
  organizationsExpandApi,
  organizationsStatusApi,
  appDetailsApi,
  appAttributesApi
}
