import { loginApi, organizationApi, userApi, appsApi, appsPendingApi, organizationsPendingApi, requestsPendingApi, appsApproveApi, appsRejectApi, appsSuspendApi, appsReviewApi, appsVersionApi, requestsApi, commentsApi, createRequestsApi, requestStatusApi, organizationsApi, organizationsExpandApi, organizationsStatusApi, appDetailsApi, appAttributesApi, requestDeviceClassStatusApi, requestDeviceClassDetailsApi, appsSecurityDetailsApi, appVersionsComparisonApi } from './apiEndpoints';
import { del, get, post, put, patch } from './restApi';
import { formatMutableUrl, formatMutableUrlId } from './routes';


const generateApiUrl = (url) => {
  if (process.env.NODE_ENV === 'production') {
    return process.env.REACT_APP_API_PREFIX + window.location.host + url;
  }
  return process.env.REACT_APP_API_PREFIX + process.env.REACT_APP_LOCATION_HOST + url
}

export const redirectToLogin = () => {
  return window.location.replace(generateApiUrl(loginApi))
}

export const getUser = (params = {}) => {
  return get(generateApiUrl(userApi), params)
}

export const getApps = (params = {}) => {
  return get(generateApiUrl(appsApi), params)
}

export const getPendingApps = (params = {}) => {
  return get(generateApiUrl(appsPendingApi), params)
}

export const getPendingOrganizations = (params = {}) => {
  return get(generateApiUrl(organizationsPendingApi), params)
}

export const getPendingRequests = (params = {}) => {
  return get(generateApiUrl(requestsPendingApi), params)
}

export const putAppApprove = (body = {}) => {
  return put(generateApiUrl(appsApproveApi), body)
}

export const putAppReject = (body = {}) => {
  return put(generateApiUrl(appsRejectApi), body)
}

export const putAppSuspend = (body = {}) => {
  return put(generateApiUrl(appsSuspendApi), body)
}

export const putAppReview = (body = {}) => {
  return put(generateApiUrl(appsReviewApi), body)
}

export const deleteAppVersion = (body = {}) => {
  return del(generateApiUrl(appsVersionApi), body)
}

export const getRequests = (params = {}) => {
  return get(generateApiUrl(requestsApi), params)
}

export const getComments = (id = "", params = {}) => {
  return get(generateApiUrl(formatMutableUrl(commentsApi, [{from: ':id', to: id}])), params)
}

export const postComments = (id = "", body = {}) => {
  return post(generateApiUrl(formatMutableUrl(commentsApi, [{from: ':id', to: id}])), body)
}

export const createRequests = (body = {}) => {
  return post(generateApiUrl(createRequestsApi), body)
}

export const changeRequestStatus = (id = "", status = "", body = {}) => {
  return put(generateApiUrl(formatMutableUrl(requestStatusApi, [{from: ':id', to: id}, {from: ':status', to: status}])), body)
}

export const changeRequestDeviceClassStatus = (id = "", status = "", body = {}) => {
  return put(generateApiUrl(formatMutableUrl(requestDeviceClassStatusApi, [{from: ':id', to: id}, {from: ':status', to: status}])), body)
}

export const getRequestDeviceClassDetails = (id = "", params = {}) => {
  return get(generateApiUrl(formatMutableUrl(requestDeviceClassDetailsApi, [{from: ':id', to: id}])), params)
}

export const getOrganizations = (params = {}) => {
  return get(generateApiUrl(organizationsApi), params)
}

export const getOrganizationsExpand = (id = "", params = {}) => {
  return get(generateApiUrl(formatMutableUrl(organizationsExpandApi, [{from: ':id', to: id}])), params)
}

export const changeOrganizationsStatus = (id = "", status = "", body = {}) => {
  return put(generateApiUrl(formatMutableUrl(organizationsStatusApi, [{from: ':id', to: id}, {from: ':status', to: status}])), body)
}

export const getAppAttributes = (id = "", params = {}) => {
  return get(generateApiUrl(formatMutableUrl(appDetailsApi, [{from: ':id', to: id}])), params)
}

export const changeAppAttributes = (id = "", body = {}) => {
  return post(generateApiUrl(formatMutableUrl(appAttributesApi, [{from: ':id', to: id}])), body)
}

export const getAppSecurityDetails = (id = "", version = "", params = {}) => {
  return get(generateApiUrl(formatMutableUrl(appsSecurityDetailsApi, [{from: ':id', to: id}, {from: ':version', to: version}])), params)
}

export const getAppVersionComparisonDetails = (id = "", version1 = "", version2 = "", params = {}) => {
  return get(generateApiUrl(formatMutableUrl(appVersionsComparisonApi, [{from: ':id', to: id}, {from: ':version1', to: version1}, {from: ':version2', to: version2}])), params)
}
