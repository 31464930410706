import React from 'react';

// Wizard width is not a t-shirt size but instead dependent
// on the max number of columns it will contain * the width
// of those columns (medium) + gap between columns + small amount
// of pad to ensure focus is visible.
export const getWidth = (numberColumns, theme, size) => {
  const inputWidth =
    parseInt(theme.global.size.medium.replace('px', ''), 10) * numberColumns
  const gapWidth =
    size !== 'small'
      ? parseInt(theme.global.edgeSize.large.replace('px', ''), 10) *
      (numberColumns - 1)
      : 0
  const focusPad =
    2 *
    (size === 'small'
      ? parseInt(theme.global.edgeSize.small.replace('px', ''), 10)
      : parseInt(theme.global.edgeSize.xxsmall.replace('px', ''), 10))
  return `${inputWidth + gapWidth + focusPad}px`
}

export const boxAlignments = ['start', 'center', 'end', 'baseline', 'stretch']

export const bytesToSize = (bytes = 0) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const sizeIndex = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  return `${Math.round(bytes / 1024 ** sizeIndex, 2)} ${sizes[sizeIndex]}`
}

export const abbreviateNumber = (value) => {
  var newValue = value;
  if (value >= 1000) {
      var suffixes = ["", "k", "m", "b","t"];
      var suffixNum = Math.floor( (""+value).length/3 );
      var shortValue = '';
      for (var precision = 2; precision >= 1; precision--) {
          shortValue = parseFloat( (suffixNum !== 0 ? (value / Math.pow(1000,suffixNum) ) : value).toPrecision(precision));
          var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g,'');
          if (dotLessShortValue.length <= 2) { break; }
      }
      if (shortValue % 1 !== 0)  shortValue = shortValue.toFixed(1);
      newValue = shortValue+suffixes[suffixNum];
  }
  return newValue;
}

export const translateDeviceClass = (deviceClassesList, val) => {
  for (let i = 0; i < deviceClassesList.length; i++) {
    if (val === deviceClassesList[i].id) {
      return deviceClassesList[i].name;
    }
  }
  return val
}