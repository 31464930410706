import { Icon, IconsEnum } from "@gravity/icons";
import { DecoratedFormControl, GvtButton, GvtIconButton, GvtInput, GvtMenuItem, GvtModalDialog, GvtSelect, SimpleTable, TypographyEnum } from "@gravity/ui-components";
import _ from "lodash";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { CreationWizardEnum, SubscriptionsFieldEnum, SubscriptionsTypesEnum } from "../enums";
import { IMaskInput } from 'react-imask';
import { StyledColumn, StyledSimpleTable, StyledSimpleTableAdd } from "../styled-components";
import { translateDeviceClass } from "../utils";
import SearchSelect from "../search-select/search-select";
import ReactMarkdown from "react-markdown";

const ZigbeeOuiMask = forwardRef(function ZigbeeOuiMask(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="##:##:##"
      definitions={{
        '#': /[0-9a-fA-F]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
})


export const SubscriptionsTable = forwardRef(({
  subscriptions = [],
  error = false,
  deviceClasses = [],
  onChange = () => { },
  readOnly = false,
  subscriptionFor = CreationWizardEnum.ble,
  disableEdit = false
}, ref) => {
  const { t } = useTranslation(['appCreation', "common"]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [subscriptionTypes, setSubscriptionTypes] = useState([]);
  const [matchType, setMatchType] = useState("");
  const [matchValueError, setMatchValueError] = useState('');

  const [matchValue, setMatchValue] = useState("");
  const [matchTypeError, setMatchTypeError] = useState('');

  const [matchPropValue, setMatchPropValue] = useState('');
  const [matchPropValueError, setMatchPropValueError] = useState('');

  const [editIndex, setEditIndex] = useState(-1);

  useEffect(() => {
    let arr = subscriptionFor == CreationWizardEnum.ble ? [
      { name: t('fields.subscriptions.fields.match_type.values.vendor.label'), id: SubscriptionsTypesEnum.vendor },
      { name: t('fields.subscriptions.fields.match_type.values.uuid.label'), id: SubscriptionsTypesEnum.uuid },
      { name: t('fields.subscriptions.fields.match_type.values.local_name.label'), id: SubscriptionsTypesEnum.local_name },
      { name: t('fields.subscriptions.fields.match_type.values.device_class.label'), id: SubscriptionsTypesEnum.device_class }
    ] : [
      { name: t('fields.subscriptions.fields.match_type.values.device_class.label'), id: SubscriptionsTypesEnum.device_class },
      { name: t('fields.subscriptions.fields.match_type.values.oui.label'), id: SubscriptionsTypesEnum.zigbee_oui }
    ]
    setSubscriptionTypes(arr)
    setMatchType(arr[0].id);
    setMatchValue(arr[0].id == SubscriptionsTypesEnum.device_class && deviceClasses.length > 0 ? deviceClasses[0].id : "");
  }, [subscriptionFor])

  useImperativeHandle(ref, () => ({
    validateSubscriptions() {
      for (let i = 0; i < subscriptions.length; i++) {
        if (validateMatchType(subscriptions[i][SubscriptionsFieldEnum.match_type]) != '' ||
          validateMatchValue(subscriptions[i][SubscriptionsFieldEnum.match_value], subscriptions[i][SubscriptionsFieldEnum.match_type]) != '') {
          return false;
        }
      }
      return true;
    }
  }));

  const validateAll = () => {
    let arr = [];
    arr.push(validateMatchType(matchType));
    arr.push(validateMatchValue(matchValue));
    arr.push(validateMatchPropValue(matchPropValue));
    arr = [...new Set(arr)];
    return !(arr.length > 1 || (arr.length == 1 && arr[0] != ''));
  }

  const changeMatchType = (e) => {
    setMatchType(e.target.value);
    validateMatchType(e.target.value);
    setMatchValue("");
    setMatchPropValue('');
    setMatchValueError('');
    setMatchPropValueError('')
    removeDuplicatedError();
  }

  const validateMatchType = (value) => {
    let errorType = !value ? 'required' : '';
    setMatchTypeError(errorType)
    return errorType;
  }

  const changeMatchValue = (e) => {
    removeDuplicatedError();
    let value = e.target.value
    validateMatchValue(value);
    setMatchValue(value);
  }

  const changeMatchPropValue = (e) => {
    removeDuplicatedError();
    let value = e.target.value
    validateMatchPropValue(value);
    setMatchPropValue(value);
  }

  const validateMatchValue = (value, match_type = matchType) => {
    let errorType = !value ? 'required' : '';
    if (!!value && (match_type === SubscriptionsTypesEnum.vendor || match_type === SubscriptionsTypesEnum.uuid)) {
      errorType = !(/^[0-9A-Fa-f]{0,4}$/g.test(value)) ? 'bytes' : '';
      if (match_type === SubscriptionsTypesEnum.vendor) {
        if (value.length === 6) { // If 2 + 1 byte
          errorType = !(/^[0-9A-Fa-f]{0,6}$/g.test(value)) ? 'bytes' : '';
        }
      }

    } else if (!!value && match_type === SubscriptionsTypesEnum.local_name) {
      let hasError = false;
      try {
        new RegExp(value);
      } catch (e) {
        hasError = true;
      }
      errorType = hasError ? 'regex' : '';
    } else if (!!value && match_type === SubscriptionsTypesEnum.zigbee_oui) {
      errorType = !(/^([0-9A-Fa-f]{2}:){2}[0-9A-Fa-f]{2}$/g.test(value)) ? 'oui' : '';
    }
    setMatchValueError(errorType)
    return errorType;
  }

  const validateMatchPropValue = (value, match_type = matchType) => {
    let errorType = '';
    if (!!value && (match_type === SubscriptionsTypesEnum.vendor)) {
      errorType = !(/^[0-9A-Fa-f]{0,2}$/g.test(value)) ? 'bytes' : '';
    }
    setMatchPropValueError(errorType);
    return errorType;
  }

  const getMatchTypeMessage = () => {
    if (matchTypeError != '') {
      return t('fields.subscriptions.fields.match_type.error.' + matchTypeError)
    }
    return ''
  }

  const getMatchValueMessage = () => {
    if (matchValueError != '') {
      return t('fields.subscriptions.fields.match_value.error.' + matchValueError)
    }
    return t('fields.subscriptions.fields.match_value.help.' + matchType.toLowerCase())
  }

  const getMatchPropValueMessage = () => {
    if (matchPropValueError != '') {
      return t('fields.subscriptions.fields.match_proprietary_value.error.' + matchPropValueError)
    }
    return t('fields.subscriptions.fields.match_proprietary_value.help.' + matchType.toLowerCase())
  }

  const openModal = () => {
    setDialogOpen(true);
    setMatchType(subscriptionTypes.length > 0 ? subscriptionTypes[0].id : "");
    setMatchValue(subscriptionTypes[0].id == SubscriptionsTypesEnum.device_class && deviceClasses.length > 0 ? deviceClasses[0].id : "");
    setMatchPropValue('');
    setEditIndex(-1);
    clearError();
  }

  const setDuplicatedError = () => {
    setMatchTypeError('duplicated')
    setMatchValueError('duplicated')
  }

  const removeDuplicatedError = () => {
    if (matchTypeError == 'duplicated') {
      setMatchTypeError('')
    }
    if (matchValueError == 'duplicated') {
      setMatchValueError('')
    }
  }

  const clearError = () => {
    setMatchTypeError('')
    setMatchValueError('')
  }

  const addSubscription = (matchType, matchValue, matchPropValue) => {
    let subscriptionsList = _.cloneDeep(subscriptions);
    let newSubscription = {
      [
        SubscriptionsFieldEnum.match_type]: matchType,
      [SubscriptionsFieldEnum.match_value]: (matchType === SubscriptionsTypesEnum.vendor ? matchValue.concat(matchPropValue) : matchValue)
    };
    let duplicated = -1;
    for (let i = 0; i < subscriptionsList.length; i++) {
      if (_.isEqual(newSubscription, subscriptionsList[i])) {
        duplicated = i;
        break;
      }
    }
    if (duplicated == -1 && editIndex == -1) {
      subscriptionsList.push(newSubscription);
      onChange(subscriptionsList);
      setDialogOpen(false);
    } else if (editIndex >= 0) {
      subscriptionsList[editIndex] = newSubscription;
      onChange(subscriptionsList);
      setDialogOpen(false);
    } else {
      setDuplicatedError();
    }
  }

  const removeSubscription = (value) => {
    let subscriptionsList = _.cloneDeep(subscriptions);
    for (let i = 0; i < subscriptionsList.length; i++) {
      if (value[SubscriptionsFieldEnum.match_type] == subscriptionsList[i][SubscriptionsFieldEnum.match_type] && value[SubscriptionsFieldEnum.match_value] == subscriptionsList[i][SubscriptionsFieldEnum.match_value]) {
        subscriptionsList.splice(i, 1);
        break;
      }
    }
    onChange(subscriptionsList);
  }

  const editSubscription = (value) => {
    setDialogOpen(true);
    for (let i = 0; i < subscriptions.length; i++) {
      if (value[SubscriptionsFieldEnum.match_type] == subscriptions[i][SubscriptionsFieldEnum.match_type] && value[SubscriptionsFieldEnum.match_value] == subscriptions[i][SubscriptionsFieldEnum.match_value]) {
        setEditIndex(i);
        break;
      }
    }
    setMatchType(value[SubscriptionsFieldEnum.match_type]);

    if (value[SubscriptionsFieldEnum.match_type] === SubscriptionsTypesEnum.vendor) {
      setMatchValue(value[SubscriptionsFieldEnum.match_value].substring(0, 4));
      setMatchPropValue(value[SubscriptionsFieldEnum.match_value].substring(4, 6));
    } else {
      setMatchValue(value[SubscriptionsFieldEnum.match_value]);
    }
    clearError();
  }

  const setMatchPropValueOnBlur = (e) => {
    if (e.target.value.length > 0) {
      setMatchPropValue(e.target.value.padStart(2, "0").toUpperCase());
    }
  }

  // Formatter for Match Propritery optional 
  const formatVendorCellValue = (value) => {
    let matchValue = value.substring(0, 4);
    let matchPropValue = value.substring(4, 6);
    if (matchPropValue.length > 0) {
      return `0x${matchValue},0x${matchPropValue}`.split(',').join('\xa0\xa0\xa0\xa0\xa0');
    }
    return `0x${matchValue}`;
  }

  return (
    <>
      <StyledSimpleTable>
        {!readOnly && !disableEdit && subscriptions.length > 0 && (<StyledSimpleTableAdd>
          <GvtIconButton onClick={() => { openModal() }} disabled={dialogOpen}>
            <Icon name={IconsEnum.FormAdd} />
          </GvtIconButton>
        </StyledSimpleTableAdd>)}
        <div className={error ? (subscriptions.length <= 0 && !readOnly ? "has-error-self" : "has-error") : ""}>
          {subscriptions.length > 0 && (<SimpleTable columns={[
            {
              field: SubscriptionsFieldEnum.match_type,
              displayName: t('fields.subscriptions.fields.match_type.label'),
              cellFormatter: (rowData, value) => {
                return (
                  <>
                    {t('fields.subscriptions.fields.match_type.values.' + value.toLowerCase() + '.label')}
                  </>
                )
              }
            },
            {
              field: SubscriptionsFieldEnum.match_value,
              displayName: t('fields.subscriptions.fields.match_value.label'),
              cellFormatter: (rowData, value) => {
                return (
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>

                    {rowData[SubscriptionsFieldEnum.match_type] == SubscriptionsTypesEnum.device_class ?
                      translateDeviceClass(deviceClasses, value) :
                      (rowData[SubscriptionsFieldEnum.match_type] === SubscriptionsTypesEnum.local_name
                        || rowData[SubscriptionsFieldEnum.match_type] === SubscriptionsTypesEnum.zigbee_oui ? value :
                        (rowData[SubscriptionsFieldEnum.match_type] === SubscriptionsTypesEnum.vendor ? formatVendorCellValue(value) : "0x" + value)
                      )}
                    {!readOnly && !disableEdit && (
                      <div>
                        <GvtIconButton onClick={() => { editSubscription(rowData) }}>
                          <Icon name={IconsEnum.FormEdit} />
                        </GvtIconButton>
                        <GvtIconButton onClick={() => { removeSubscription(rowData) }}>
                          <Icon name={IconsEnum.FormTrash} />
                        </GvtIconButton>
                      </div>
                    )}
                  </div>
                )
              }
            }
          ]} rows={subscriptions} rowsRestriction={subscriptions.length + 1} />)}
          {subscriptions.length <= 0 && !readOnly && (<GvtButton color='secondary' onClick={() => openModal()} disabled={dialogOpen}>{t('fields.subscriptions.new.title')}</GvtButton>)}
          {subscriptions.length <= 0 && readOnly && (<div>{t('fields.subscriptions.no_data')}</div>)}
        </div>
      </StyledSimpleTable>
      <GvtModalDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onBackdropClick={() => setDialogOpen(false)}
        title={t('fields.subscriptions.new.title')}
        buttons={[
          {
            onClick: () => setDialogOpen(false),
            children: t('cancel', { ns: "common" })
          },
          {
            onClick: () => {
              if (validateAll()) {
                addSubscription(matchType, matchValue, matchPropValue);
              }
            },
            color: 'primary',
            children: t('save', { ns: "common" })
          }
        ]}
      >
        <StyledColumn>
          <DecoratedFormControl required={true} label={t('fields.subscriptions.fields.match_type.label')} description={t('fields.subscriptions.fields.match_type.description')} error={matchTypeError != ''} info={getMatchTypeMessage()}>
            <GvtSelect onChange={changeMatchType} value={matchType}>
              {subscriptionTypes.map((subscription, index) => (
                <GvtMenuItem key={'subscription' + index} value={subscription.id}>{subscription.name}</GvtMenuItem>
              ))}
            </GvtSelect>
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.subscriptions.fields.match_value.label')} description={t('fields.subscriptions.fields.match_value.description')} error={matchValueError != ''} info={getMatchValueMessage()}>
            {(matchType === SubscriptionsTypesEnum.vendor || matchType === SubscriptionsTypesEnum.uuid) && (
              <GvtInput
                onBlur={(e) => { setMatchValue(e.target.value.padStart(4, "0").toUpperCase()) }}
                onChange={changeMatchValue}
                value={matchValue}
                inputProps={{ maxLength: 4 }}
                startAdornment={<>0x</>}
              />
            )}
            {(matchType === SubscriptionsTypesEnum.zigbee_oui) && (
              <GvtInput
                onBlur={(e) => { setMatchValue(e.target.value.toUpperCase()) }}
                onChange={changeMatchValue}
                value={matchValue}
                inputComponent={ZigbeeOuiMask} />
            )}
            {matchType === SubscriptionsTypesEnum.local_name && (<GvtInput onChange={changeMatchValue} value={matchValue} />)}
            {matchType === SubscriptionsTypesEnum.device_class && (
              <SearchSelect
                optionKey="id"
                onChange={changeMatchValue}
                value={matchValue}
                displayValue={(value) => { return translateDeviceClass(deviceClasses, value.id) }}
                onSearch={(option, searchText) => {
                  let text = translateDeviceClass(deviceClasses, option.id).toString();
                  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
                }}
                options={deviceClasses}
                onRender={(value) => {
                  return translateDeviceClass(deviceClasses, value);
                }}
                notFoundExtra={<ReactMarkdown
                  components={{
                    p: ({ children }) => (
                      <div className={TypographyEnum.FormDescription}>
                        {children}
                      </div>
                    ),
                  }}
                >{t('fields.app_allowed_device_class_classification.not_found')}</ReactMarkdown>}
              ></SearchSelect>
            )}
          </DecoratedFormControl>

          {(matchType === SubscriptionsTypesEnum.vendor) && (
            <DecoratedFormControl
              label={t('fields.subscriptions.fields.match_proprietary_value.label')}
              error={matchPropValueError != ''}
              info={getMatchPropValueMessage()}>
              <GvtInput
                onBlur={(e) => { setMatchPropValueOnBlur(e) }}
                onChange={changeMatchPropValue}
                value={matchPropValue}
                inputProps={{ maxLength: 2 }}
                startAdornment={<>0x</>} />
            </DecoratedFormControl>
          )}

        </StyledColumn>
      </GvtModalDialog>
    </>
  )
})

export default SubscriptionsTable