import { Icon, IconsEnum } from "@gravity/icons";
import { DecoratedFormControl, GvtButton, GvtIconButton, GvtInput, GvtMenuItem, GvtModalDialog, GvtSelect, SimpleTable } from "@gravity/ui-components";
import _ from "lodash";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HtmlTooltip, StyledColumn, StyledDecoratedFormControl, StyledSimpleTable, StyledSimpleTableAdd } from "../styled-components";
import { DefaultLuaData, UsbDevicesFieldEnum, UsbDevicesInterfaceConfigListFieldEnum, UsbInterfaceNumberEnum } from "../enums";
import { LuaEditor } from "../lua-editor/lua-editor";

const LuaTooltip = ({ value, opened = false, onOpen = () => {}, disableEdit = false }) => {
  const { t } = useTranslation(['appCreation', "common"]);
  const [open, setOpen] = useState(false);
  const [clickedOpen, setClickedOpen] = useState(false);

  const text = t('yes', { ns: "common" });

  useEffect(() => {
    if (!opened && clickedOpen) {
      setOpen(false);
      setClickedOpen(false);
    }
  }, [!opened])

  const handleTooltipClose = () => {
    setOpen(false);
    setClickedOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const handleTooltipOpenOnClick = () => {
    setClickedOpen(!clickedOpen);
    onOpen(!clickedOpen);
  };

  const getOpenTest = () => {
    return clickedOpen ? t('fields.usb_config.fields.interfaces.fields.lua_script.tooltip.close', { value: text }) : t('fields.usb_config.fields.interfaces.fields.lua_script.tooltip.open', { value: text });
  }

  return (
    <HtmlTooltip
      onClose={handleTooltipClose}
      onMouseOver={handleTooltipOpen}
      onMouseOut={() => { setOpen(false) }}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      open={open || clickedOpen}
      placement="left"
      arrow
      title={
        <>
          <div>{getOpenTest()}</div>
          <div style={{ width: "500px" }}>
            <LuaEditor
              content={disableEdit ? atob(value.data) : value.data}
              readOnly={true}
              hideName={true}>
            </LuaEditor>
          </div>
        </>
      }>
      <div onClick={handleTooltipOpenOnClick}>{text}</div>
    </HtmlTooltip>
  )
}

export const InterfacesConfigTable = forwardRef(({
  interfaces = [],
  error = false,
  onChange = () => { },
  onAddNew = () => { },
  driverNames = [],
  baudrates = [],
  readOnly = false,
  serialData = false,
  showLuaTooltip = true,
  disableEdit = false
}, ref) => {
  const { t } = useTranslation(['appCreation', "common"]);
  const [openNew, setOpenNew] = useState(false);

  const [interfaceNo, setInterfaceNo] = useState(UsbInterfaceNumberEnum.min);
  const [driverName, setDriverName] = useState(driverNames.length > 0 ? driverNames[0] : "");
  const [luaScript, setLuaScript] = useState(DefaultLuaData);
  const [luaFile, setLuaFile] = useState(new File([""], "script.lua"));
  const [baudrate, setBaudrate] = useState(serialData && baudrates.length > 0 ? baudrates[0] : "");
  const [filteredInterfaces, setFilteredInterfaces] = useState([]);
  const [editIndex, setEditIndex] = useState(-1);

  const [interfaceNoError, setInterfaceNoError] = useState("");
  const [driverNameError, setDriverNameError] = useState("");
  const [luaScriptError, setLuaScriptError] = useState("");
  const [baudrateError, setBaudrateError] = useState("");
  const [anyOpenTooltip, setAnyOpenTooltip] = useState(false);

  const luaRef = useRef();

  useEffect(() => {
    if (readOnly) {
      setFilteredInterfaces(interfaces)
    } else {
      setFilteredInterfaces(interfaces.filter(value => value[UsbDevicesFieldEnum.serial_data] == serialData));
    }
  }, [interfaces, serialData, readOnly])

  useImperativeHandle(ref, () => ({
    validateInterfaces(value = filteredInterfaces) {
      for (let i = 0; i < value.length; i++) {
        if (validateInterfaceNo(value[i][UsbDevicesInterfaceConfigListFieldEnum.number], true) != '' ||
          validateDriverName(value[i][UsbDevicesInterfaceConfigListFieldEnum.device_driver_name]) != '' ||
          validateLuaScript(value[i][UsbDevicesInterfaceConfigListFieldEnum.lua_script]) != '' ||
          validateBaudrate(value[i][UsbDevicesInterfaceConfigListFieldEnum.baudrate]) != '') {
          return false;
        }
      }
      return true;
    },
    closeAllTooltips() {
      setAnyOpenTooltip(false);
    }
  }));

  const validateAll = () => {
    let arr = [validateInterfaceNo(interfaceNo), validateDriverName(driverName)]
    if (serialData) {
    arr.push(...[validateLuaScript(luaScript.data, !luaRef.current.validateCode()), validateBaudrate(baudrate)])
    }
    arr = [...new Set(arr)];
    return !(arr.length > 1 || (arr.length == 1 && arr[0] != ''));
  }

  const changeInterfaceNo = (e) => {
    let value = e.target.value
    validateInterfaceNo(value);
    setInterfaceNo(value);
  }

  const validateInterfaceNo = (value, ignore_duplicated = false) => {
    let errorType = '';
    if (value && !ignore_duplicated) {
      for (let i = 0; i < filteredInterfaces.length; i++) {
        if (value == filteredInterfaces[i][UsbDevicesInterfaceConfigListFieldEnum.number] && i != editIndex) {
          errorType = 'duplicated';
          break;
        }
      }
    }
    if (isNaN(Number(value)) && errorType == '') {
      errorType = 'required';
    } else if (Number(value) < UsbInterfaceNumberEnum.min && errorType == '') {
      errorType = 'min';
    } else if (Number(value) > UsbInterfaceNumberEnum.max && errorType == '') {
      errorType = 'max';
    } else if (!Number.isInteger(Number(value)) && errorType == '') {
      errorType = 'invalid';
    }
    setInterfaceNoError(errorType);
    return errorType;
  }

  const changeDriverName = (e) => {
    let value = e.target.value
    validateDriverName(value);
    setDriverName(value);
  }

  const validateDriverName = (value) => {
    let errorType = !value ? 'required' : '';
    setDriverNameError(errorType);
    return errorType;
  }

  const changeLuaScript = (value, file, codeError) => {
    let lua = _.cloneDeep(luaScript);
    lua.data = value;
    lua.name = file?.name ? file?.name : '';;
    lua.file_id = null;
    setLuaScript(lua);
    setLuaFile(file);
    validateLuaScript(value, codeError);
  }

  const validateLuaScript = (value, codeError = false) => {
    let errorType = serialData && !value ? 'required' : '';
    if (errorType == '' && codeError) {
      errorType = 'code';
    }
    setLuaScriptError(errorType);
    return errorType;
  }

  const changeBaudrate = (e) => {
    let value = e.target.value
    validateBaudrate(value);
    setBaudrate(value);
  }

  const validateBaudrate = (value) => {
    let errorType = serialData && !value ? 'required' : '';
    setBaudrateError(errorType);
    return errorType;
  }

  const getMessage = (key, value) => {
    if (value != '') {
      return t('fields.usb_config.fields.interfaces.fields.' + key + '.error.' + value)
    }
    return t('fields.usb_config.fields.interfaces.fields.' + key + '.help')
  }


  const openNewInterface = () => {
    setAnyOpenTooltip(false);
    setOpenNew(true);
    setInterfaceNo(UsbInterfaceNumberEnum.min);
    setDriverName(driverNames.length > 0 ? driverNames[0] : "");
    setLuaScript({ data: "", name: "script.lua" });
    setLuaFile(new File([""], "script.lua"));
    setBaudrate(serialData && baudrates.length > 0 ? baudrates[0] : "");
    setFilteredInterfaces(interfaces.filter(value => value[UsbDevicesFieldEnum.serial_data] == serialData));
    clearError();
    onAddNew(true);
    setEditIndex(-1);
  }

  const clearError = () => {
    setInterfaceNoError("");
    setDriverNameError("");
    setLuaScriptError("");
    setBaudrateError("");
  }

  const addNew = () => {
    let list = _.cloneDeep(interfaces);
    let newConfig = {
      [UsbDevicesInterfaceConfigListFieldEnum.number]: interfaceNo,
      [UsbDevicesInterfaceConfigListFieldEnum.device_driver_name]: driverName,
      [UsbDevicesInterfaceConfigListFieldEnum.lua_script]: luaScript,
      [UsbDevicesInterfaceConfigListFieldEnum.baudrate]: baudrate,
      [UsbDevicesFieldEnum.serial_data]: serialData
    }
    onChange(list);
    setOpenNew(false);
    onAddNew(false);
    if (editIndex == -1) {
      list.push(newConfig);
    } else {
      list[editIndex] = newConfig;
    }
    onChange(list);
    setOpenNew(false);
    onAddNew(false);
  }

  const remove = (value) => {
    setAnyOpenTooltip(false);
    let list = _.cloneDeep(interfaces);
    for (let i = 0; i < list.length; i++) {
      if (_.isEqual(value, list[i])) {
        list.splice(i, 1);
        break;
      }
    }
    onChange(list);
  }

  const edit = (value) => {
    setAnyOpenTooltip(false);
    setOpenNew(true);
    for (let i = 0; i < interfaces.length; i++) {
      if (_.isEqual(value, interfaces[i])) {
        setEditIndex(i);
        break;
      }
    }
    setInterfaceNo(value[UsbDevicesInterfaceConfigListFieldEnum.number]);
    setDriverName(value[UsbDevicesInterfaceConfigListFieldEnum.device_driver_name]);
    setLuaScript(value[UsbDevicesInterfaceConfigListFieldEnum.lua_script]);
    setLuaFile(new File([value[UsbDevicesInterfaceConfigListFieldEnum.lua_script].data], value[UsbDevicesInterfaceConfigListFieldEnum.lua_script].name));
    setBaudrate(value[UsbDevicesInterfaceConfigListFieldEnum.baudrate]);
    setFilteredInterfaces(interfaces.filter(value => value[UsbDevicesFieldEnum.serial_data] == serialData));
    clearError();
    onAddNew(true);
  }

  const getColumns = () => {
    let cols = [
      {
        field: UsbDevicesInterfaceConfigListFieldEnum.number,
        displayName: t('fields.usb_config.fields.interfaces.fields.number.label')
      }
    ]
    if (!serialData) {
      cols.push({
        field: UsbDevicesInterfaceConfigListFieldEnum.device_driver_name,
        displayName: t('fields.usb_config.fields.interfaces.fields.device_driver_name.label'),
        cellFormatter: (rowData, value) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {value}
              {!readOnly && (
                <div>
                  <GvtIconButton onClick={() => { edit(rowData) }}>
                    <Icon name={IconsEnum.FormEdit} />
                  </GvtIconButton>
                  <GvtIconButton onClick={() => { remove(rowData) }}>
                    <Icon name={IconsEnum.FormTrash} />
                  </GvtIconButton>
                </div>
              )}
            </div>
          )
        }
      })
    } else {
      cols.push(...[{
        field: UsbDevicesInterfaceConfigListFieldEnum.device_driver_name,
        displayName: t('fields.usb_config.fields.interfaces.fields.device_driver_name.label')
      },{
        field: UsbDevicesInterfaceConfigListFieldEnum.lua_script,
        displayName: t('fields.usb_config.fields.interfaces.fields.lua_script.label'), cellFormatter: (rowData, value) => {
          return (
            <>
              {value.data && showLuaTooltip && (<LuaTooltip value={value} disableEdit={disableEdit} onOpen={(val) => setAnyOpenTooltip(anyOpenTooltip ? true : val)} opened={anyOpenTooltip}></LuaTooltip>)}
              {value.data && !showLuaTooltip && (<>{t('yes', { ns: "common" })}</>)}
              {!value.data && (<>{t('no', { ns: "common" })}</>)}
            </>
          )
        }
      },{
        field: UsbDevicesInterfaceConfigListFieldEnum.baudrate,
        displayName: t('fields.usb_config.fields.interfaces.fields.baudrate.label'),
        cellFormatter: (rowData, value) => {
          return (
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              {value ? value.replace(/[^0-9]/g, '') : t('no', { ns: "common" })}
              {!readOnly && !disableEdit && (
                <div>
                  <GvtIconButton onClick={() => { edit(rowData) }}>
                    <Icon name={IconsEnum.FormEdit} />
                  </GvtIconButton>
                  <GvtIconButton onClick={() => { remove(rowData) }}>
                    <Icon name={IconsEnum.FormTrash} />
                  </GvtIconButton>
                </div>
              )}
            </div>
          )
        }
      }])
    }
    return cols;
  }

  return (
    <>
      {filteredInterfaces.length > 0 && (<StyledSimpleTable>
        {!readOnly && !disableEdit && (<StyledSimpleTableAdd>
          <GvtIconButton onClick={() => { openNewInterface() }} disabled={openNew}>
            <Icon name={IconsEnum.FormAdd} />
          </GvtIconButton>
        </StyledSimpleTableAdd>)}
        <div className={error ? "has-error" : ""}>
          <SimpleTable columns={getColumns()} rows={filteredInterfaces} rowsRestriction={filteredInterfaces.length + 1} />
        </div>
      </StyledSimpleTable>
      )}
      {filteredInterfaces.length <= 0 && (<StyledSimpleTable><div className={error ? "has-error-self" : ""}>
        <GvtButton color='secondary' onClick={() => openNewInterface()} disabled={openNew}>{t('fields.usb_config.fields.interfaces.new')}</GvtButton>
      </div></StyledSimpleTable>)}
      <GvtModalDialog
        open={openNew}
        onClose={() => { onAddNew(false); setOpenNew(false) }}
        onBackdropClick={() => { onAddNew(false); setOpenNew(false) }}
        title={t('fields.usb_config.fields.interfaces.new')}
        buttons={[
          {
            onClick: () => { onAddNew(false); setOpenNew(false) },
            children: t('cancel', { ns: "common" })
          },
          {
            onClick: () => {
              if (validateAll()) {
                addNew();
              }
            },
            color: 'primary',
            children: t('add', { ns: "common" })
          }
        ]}
      >
        <StyledColumn>
          <DecoratedFormControl required={true} label={t('fields.usb_config.fields.interfaces.fields.number.label')} description={t('fields.usb_config.fields.interfaces.fields.number.description')} error={interfaceNoError != ''} info={getMessage(UsbDevicesInterfaceConfigListFieldEnum.number, interfaceNoError)}>
            <GvtInput onChange={changeInterfaceNo} value={interfaceNo} type="number" inputProps={{ min: UsbInterfaceNumberEnum.min, max: UsbInterfaceNumberEnum.max, step: UsbInterfaceNumberEnum.step }} />
          </DecoratedFormControl>
          <DecoratedFormControl required={true} label={t('fields.usb_config.fields.interfaces.fields.device_driver_name.label')} description={t('fields.usb_config.fields.interfaces.fields.device_driver_name.description')} error={driverNameError != ''} info={getMessage(UsbDevicesInterfaceConfigListFieldEnum.device_driver_name, driverNameError)}>
            <GvtSelect onChange={changeDriverName} value={driverName}>
              {driverNames.map((driver, index) => (
                <GvtMenuItem key={'driver' + index} value={driver}>{driver}</GvtMenuItem>
              ))}
            </GvtSelect>
          </DecoratedFormControl>
          {serialData && (<StyledDecoratedFormControl required={true} label={t('fields.usb_config.fields.interfaces.fields.lua_script.label')} description={t('fields.usb_config.fields.interfaces.fields.lua_script.description')} error={luaScriptError != ''} info={getMessage(UsbDevicesInterfaceConfigListFieldEnum.lua_script, luaScriptError)}>
            <LuaEditor
              onChange={changeLuaScript}
              file={luaFile}
              hideName={true}
              content={luaScript?.data}
              ref={luaRef}
              error={luaScriptError != ''}
              errorOnCode={luaScriptError == 'code'}
            ></LuaEditor>
          </StyledDecoratedFormControl>)}
          {serialData && (<DecoratedFormControl required={true} label={t('fields.usb_config.fields.interfaces.fields.baudrate.label')} description={t('fields.usb_config.fields.interfaces.fields.baudrate.description')} error={baudrateError != ''} info={getMessage(UsbDevicesInterfaceConfigListFieldEnum.baudrate, baudrateError)}>
            <GvtSelect onChange={changeBaudrate} value={baudrate} >
              {!serialData && (<GvtMenuItem key={'baudrate-blank'} value={""}>{t('fields.usb_config.fields.interfaces.fields.baudrate.no_baudrate')}</GvtMenuItem>)}
              {baudrates.map((baudrate, index) => (
                <GvtMenuItem key={'baudrate' + index} value={baudrate}>{baudrate.replace(/[^0-9]/g, '')}</GvtMenuItem>
              ))}
            </GvtSelect>
          </DecoratedFormControl>)}
        </StyledColumn>
      </GvtModalDialog>
    </>
  )
});

export default InterfacesConfigTable;