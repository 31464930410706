const OganizationStatesEnum = {
  CCS_PROVISIONED: "CCS_PROVISIONED",
  ENABLE_REQUESTED: "ENABLE_REQUESTED",
  ENABLED: "ENABLE_APPROVED",
  ENABLE_REJECTED: "ENABLE_REJECTED",
  SUSPENDED: "SUSPENDED",
  CCS_UNPROVISIONED: "CCS_UNPROVISIONED",
}

const AppStatusEnum = {
  APPROVED_APP: 'APPROVED_APP',
  DRAFT: 'DRAFT',
  PENDING_DRAFT: 'PENDING_DRAFT',
  IN_REVIEW: 'IN_REVIEW',
  REJECTED_DRAFT: 'REJECTED_DRAFT',
  SUSPENDED: 'SUSPENDED'
}

const CreationWizardEnum = {
  app_details: 'app_details',
  ble: 'feature_ble',
  zigbee: 'feature_zigbee',
  usb: 'feature_usb',
  wifi_rtls: 'feature_wifi_rtls',
  edge_compute: 'feature_edge_compute',
  ap_beacons_management: 'feature_ap_beacons_management',
  iot_radio_firmware_management: 'feature_iot_radio_firmware_management',
  review: 'review'
}

const SupportedPlatformsEnum = {
  legacy_ap: 'LEGACY_AP',
  ap: 'AP',
  data_collector: 'DATA_COLLECTOR'
}

const AppFieldsEnum = {
  name: 'name',
  icon: 'icon',
  summary: 'summary',
  description: 'description',
  release_notes: 'release_notes',
  author_website: 'author_website',
  platform_url: 'platform_url',
  app_support: 'app_support',
  privacy_policy_url: 'privacy_policy_url',
  license_agreement_url: 'license_agreement_url',
  categories: 'categories',
  supported_platforms: 'supported_platforms',
  minimum_sdk_version: 'minimum_sdk_version',
  app_features: 'app_features',
  feature_ble: CreationWizardEnum.ble,
  feature_zigbee: CreationWizardEnum.zigbee,
  feature_usb: CreationWizardEnum.usb,
  feature_edge_compute: CreationWizardEnum.edge_compute,
  feature_wifi_rtls: CreationWizardEnum.wifi_rtls,
  feature_ap_beacons_management: CreationWizardEnum.ap_beacons_management,
  feature_iot_radio_firmware_management: CreationWizardEnum.iot_radio_firmware_management,
  lua_script: 'lua_script',
  lua_subscription_list: 'lua_subscription_list',
  zigbee_config: 'zigbee_config',
  usb_config: 'usb_config',
  container_image_uuid: 'container_image_uuid',
  container_ap_image_uuid: 'container_ap_image_uuid',
  container_cpu_ap: 'container_cpu_ap',
  container_cpu_mini_dc: 'container_cpu_mini_dc',
  container_cpu_small_dc: 'container_cpu_small_dc',
  container_cpu_medium_dc: 'container_cpu_medium_dc',
  container_mem_ap: 'container_mem_ap',
  container_mem_mini_dc: 'container_mem_mini_dc',
  container_mem_small_dc: 'container_mem_small_dc',
  container_mem_medium_dc: 'container_mem_medium_dc',
  container_subscription_list: 'container_subscription_list',
  allowed_external_urls: 'allowed_external_urls',
  app_ap_permission: 'app_ap_permission',
  app_radio_permission: 'app_radio_permission',
  app_beacon_permission: 'app_beacon_permission',
  required_user_permission: 'required_user_permission',
  required_user_subscription: 'required_user_subscription',
  required_user_outbound_firewall_permission: 'required_user_outbound_firewall_permission',
  required_user_ca_certificates: 'required_user_ca_certificates',
  container_required_environment_variables: 'container_required_environment_variables',
  app_device_class_permissions: 'app_device_class_permissions',
  app_allowed_device_class_classification: 'app_allowed_device_class_classification'
}

const AppDeviceClassPermissionsFieldEnum = {
  device_class: 'device_class',
  ble_script_access: 'ble_script_access',
  zigbee_access: 'zigbee_access',
  serial_access: 'serial_access',
  device_context_access: 'device_context_access',
  ble_gatt_operations: 'ble_gatt_operations'
}

const PermissionsEnum = {
  block: 'BLOCK',
  read_only: 'READ_ONLY',
  read_write: 'READ_WRITE'
}

const SubscriptionsFieldEnum = {
  match_type: 'match_type',
  match_value: 'match_value'
}

const SubscriptionsTypesEnum = {
  vendor: 'VENDOR',
  uuid: 'UUID',
  local_name: 'LOCAL_NAME',
  device_class: 'DEVICE_CLASS',
  zigbee_oui: 'OUI'
}

const ZigbeeConfigFieldEnum = {
  config_type: 'config_type',
  src_endpoint: 'src_endpoint',
  dest_endpoint: 'dest_endpoint',
  profile_id: 'profile_id',
  cluster_id: 'cluster_id',
  aps_ack: 'aps_ack'
}

const ZigbeeConfigTypesEnum = {
  northbound: 'NORTH_BOUND',
  southbound: 'SOUTH_BOUND'
}

const ZigbeeConfigEnpointValuesEnum = {
  min: 0,
  max: 254
}

const OutboundFirewallPermissionsTableFieldEnum = {
  destination: 'destination'
}

const UsbDevicesFieldEnum = {
  name: 'name',
  device_class: 'device_class',
  serial_data: 'serial_data',
  descriptors: 'descriptors',
  interfaces: 'interfaces'
}

const UsbDevicesDescriptorListFieldEnum = {
  product_id: 'product_id',
  vendor_id: 'vendor_id',
  manufacture: 'manufacture',
  product_description: 'product_description',
  device_revision: 'device_revision',
  usb_version: 'usb_version'
}

const UsbDevicesInterfaceConfigListFieldEnum = {
  number: 'number',
  device_driver_name: 'device_driver_name',
  lua_script: 'lua_script',
  baudrate: 'baudrate'
}

const UsbInterfaceNumberEnum = {
  min: 0,
  max: 255,
  step: 1
}

const ImageTypesEnum = {
  ap: 'AP',
  data_collector: 'DATA_COLLECTOR'
}

const EnvironmentVariablesFieldEnum = {
  name: 'name',
  description: 'description',
  default: 'default',
  placeholder: 'placeholder',
  validation: 'validation',
  required: 'required',
  supported_values: 'supported_values'
}


const UploadContainerWizardEnum = {
  prepare: 'prepare_s3_config',
  upload: 'upload_image_to_s3'
}


const PlatformTypeEnum = {
  ap: 'RUNS_ON_AP',
  data_collector: 'RUNS_ON_COLLECTOR'
}


const ImageCategoryEnum = {
  new_file: 'new_image_file',
  new_version: 'new_image_version'
}

const ContainerCPUValuesEnum = {
  min: 0.1,
  step: 0.1,
  max_mini_dc: 2,
  max_small_dc: 4,
  max_medium_dc: 10,
  max_ap: 1
}

const ContainerMemoryValuesEnum = {
  min: 10,
  step: 1,
  max_mini_dc: 1000,
  max_small_dc: 2000,
  max_medium_dc: 5000,
  max_ap: 500
}

const ContainerPersistentMemoryValuesEnum = {
  min: 0,
  step: 1,
  max: 1024,
}

const DefaultIconData = { name: '', data: '', url: '' };

const DefaultLuaData = { name: '', data: '', file_id: '' };

const AppVersionComparisonEnums = {
  version: 'version',
  is_live: 'is_live',
  is_last_version: 'is_last_version',
  version_status: 'status',
  created_by: 'created_by',
  modified_by: 'modified_by',
  is_beta: 'is_beta',
  last_gaversion: 'last_gaversion',
  beta_customer_list: 'beta_customer_list',
  APPROVED_APP: 'Approved',
  DRAFT: 'Draft',
  PENDING_DRAFT: 'Pending',
  IN_REVIEW: 'In Review',
  REJECTED_DRAFT: 'Rejected',
  SUSPENDED: 'Suspended',
  LEGACY_AP: "AP3xx and AP5xx",
  AP: "AP6xx and above",
  DATA_COLLECTOR: "Data Collector"
}

module.exports = {
  OganizationStatesEnum,
  AppStatusEnum,
  CreationWizardEnum,
  SupportedPlatformsEnum,
  AppFieldsEnum,
  AppDeviceClassPermissionsFieldEnum,
  PermissionsEnum,
  SubscriptionsFieldEnum,
  SubscriptionsTypesEnum,
  ZigbeeConfigFieldEnum,
  ZigbeeConfigTypesEnum,
  OutboundFirewallPermissionsTableFieldEnum,
  ImageTypesEnum,
  EnvironmentVariablesFieldEnum,
  UploadContainerWizardEnum,
  PlatformTypeEnum,
  ContainerCPUValuesEnum,
  ContainerMemoryValuesEnum,
  ContainerPersistentMemoryValuesEnum,
  ZigbeeConfigEnpointValuesEnum,
  UsbDevicesFieldEnum,
  UsbDevicesDescriptorListFieldEnum,
  UsbDevicesInterfaceConfigListFieldEnum,
  UsbInterfaceNumberEnum,
  DefaultIconData,
  DefaultLuaData,
  ImageCategoryEnum,
  AppVersionComparisonEnums
}