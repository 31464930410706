import { Icon, IconsEnum } from "@gravity/icons";
import { TypographyEnum } from "@gravity/ui-components";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { StyledAccordionTitle } from "../styled-components";

export const AccordionHeader = ({
  title = '',
  error = false
}) => {
  const { t } = useTranslation(['appCreation']);

  return (
    <StyledAccordionTitle>
      <div className={TypographyEnum.TextBoldLarge}>{title}</div>
      {error && (
        <Tooltip title={t('section_error')}>
          <div><Icon name={IconsEnum.HealthLargePoor} /></div>
        </Tooltip>
      )}
    </StyledAccordionTitle>
  )
}

export default AccordionHeader;