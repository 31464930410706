import { Icon, IconsEnum } from "@gravity/icons";
import { DecoratedFormControl, GvtButton, GvtIconButton, GvtInput, GvtModalDialog, SimpleTable } from "@gravity/ui-components";
import _ from "lodash";
import { forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";
import { OutboundFirewallPermissionsTableFieldEnum } from "../enums";
import { StyledColumn, StyledSimpleTable, StyledSimpleTableAdd } from "../styled-components";

export const OutboundFirewallPermissionsTable = forwardRef(({
  permissions = [],
  error = false,
  onChange = () => { },
  readOnly = false,
  disableEdit = false
}, ref) => {
  const { t } = useTranslation(['appCreation', "common"]);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [destination, setDestination] = useState("");
  const [destinationError, setDestinationError] = useState('');
  const [editIndex, setEditIndex] = useState(-1);

  useImperativeHandle(ref, () => ({
    validatePermissions() {
      for (let i = 0; i < permissions.length; i++) {
        if (validateDestination(permissions[i]) != '' ) {
          return false;
        }
      }
      return true;
    }
  }));

  const validateAll = () => {
    return validateDestination(destination) == '';
  }

  const changeDestination = (e) => {
    validateDestination(e.target.value);
    setDestination(e.target.value);
  }

  const validateDestination = (value) => {
    let url;
    let hasProtocol = false
    let errorType = '';
    let fdqnRegex = new RegExp("^([-a-zA-Z0-9_*]+[.]?)+$");
    if (value) {
      try {
        url = new URL(value);
        hasProtocol = 'protocol';
        errorType = 'protocol';
      } catch (e) { }
      if (!hasProtocol) {
        try {
          url = new URL('http://' + value);
          errorType = '';
        } catch (_) {
          errorType = 'invalid';
        }
      }
      if(value.includes("?") || value.includes("*")) {
        errorType = 'wildcard';
      }
      if(!fdqnRegex.test(value)) {
        errorType = 'fqdn_pattern';
      }
    } else {
      errorType = 'required';
    }
    setDestinationError(errorType);
    return errorType;
  }


  const getMessage = () => {
    if (destinationError != '') {
      return t('fields.allowed_external_urls.fields.destination.error.' + destinationError);
    }
    return t('fields.allowed_external_urls.fields.destination.help');
  }

  const openModal = () => {
    setDialogOpen(true);
    setDestination("");
    setDestinationError('');
    setEditIndex(-1);
  }

  const addDestination = (destination) => {
    let permissionsList = _.cloneDeep(permissions);
    let duplicated = permissionsList.indexOf(destination);
    if (duplicated < 0 && editIndex == -1) {
      permissionsList.push(destination);
      onChange(permissionsList);
      setDialogOpen(false);
      setDestinationError('');
    } else if (editIndex >= 0) {
      permissionsList[editIndex] = destination;
      onChange(permissionsList);
      setDialogOpen(false);
      setDestinationError('');
    } else {
      setDestinationError('duplicated');
    }
  }

  const removeDestination = (value) => {
    let permissionsList = _.cloneDeep(permissions);
    for (let i = 0; i < permissionsList.length; i++) {
      if (value == permissionsList[i]) {
        permissionsList.splice(i, 1);
        break;
      }
    }
    onChange(permissionsList);
  }

  const editDestination = (value) => {
    for (let i = 0; i < permissions.length; i++) {
      if ((value[OutboundFirewallPermissionsTableFieldEnum.destination] || value) == permissions[i]) {
        setEditIndex(i);
        break;
      }
    }
    setDialogOpen(true);
    setDestination(value[OutboundFirewallPermissionsTableFieldEnum.destination] || value);
    setDestinationError('');
  }

  return (
    <>
      <StyledSimpleTable>
        {!readOnly && !disableEdit && permissions.length > 0 && (<StyledSimpleTableAdd>
          <GvtIconButton onClick={() => { openModal() }} disabled={dialogOpen}>
            <Icon name={IconsEnum.FormAdd} />
          </GvtIconButton>
        </StyledSimpleTableAdd>)}
        <div className={error ? (permissions.length <= 0 && !readOnly ? "has-error-self" : "has-error") : ""}>
          {permissions.length > 0 && (<SimpleTable columns={[
            {
              field: OutboundFirewallPermissionsTableFieldEnum.destination,
              displayName: t('fields.allowed_external_urls.fields.destination.label'),
              cellFormatter: (rowData, value) => {
                const maxCharacters = 30;
                var truncatedValue = value;
                if(value.length > maxCharacters) {
                  truncatedValue = value.substring(0, maxCharacters) + '...';
                }
                return (
                  <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                    <span title={value}>{truncatedValue}</span>
                    {!readOnly && !disableEdit && (
                      <div>
                        <GvtIconButton onClick={() => { editDestination(rowData) }}>
                          <Icon name={IconsEnum.FormEdit} />
                        </GvtIconButton>
                        <GvtIconButton onClick={() => { removeDestination(value) }}>
                          <Icon name={IconsEnum.FormTrash} />
                        </GvtIconButton>
                      </div>
                    )}
                  </div>
                )
              }
            }
          ]} rows={permissions.map((val) => { return { [OutboundFirewallPermissionsTableFieldEnum.destination]: val } })} rowsRestriction={permissions.length + 1} />)}
          {permissions.length <= 0 && !readOnly && (<GvtButton color='secondary' onClick={() => openModal()} disabled={dialogOpen}>{t('fields.allowed_external_urls.new.title')}</GvtButton>)}
          {permissions.length <= 0 && readOnly && (<div>{t('fields.allowed_external_urls.no_data')}</div>)}
        </div>
      </StyledSimpleTable>
      <GvtModalDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onBackdropClick={() => setDialogOpen(false)}
        title={
          editIndex !== -1 ? t('fields.allowed_external_urls.edit.title'):
          t('fields.allowed_external_urls.new.title')
        }
        buttons={[
          {
            onClick: () => setDialogOpen(false),
            children: t('cancel', { ns: "common" })
          },
          {
            onClick: () => {
              if (validateAll()) {
                addDestination(destination);
              }
            },
            color: 'primary',
            children: t('save', { ns: "common" })
          }
        ]}
      >
        <StyledColumn>
          <DecoratedFormControl required={true} label={t('fields.allowed_external_urls.fields.destination.label')} description={t('fields.allowed_external_urls.fields.destination.description')} error={destinationError != ''} info={getMessage()}>
            <GvtInput onChange={changeDestination} value={destination} />
          </DecoratedFormControl>
        </StyledColumn>
      </GvtModalDialog>
    </>
  )
})

export default OutboundFirewallPermissionsTable